import React, {useEffect,useState} from 'react'
import { Collapse } from "react-collapse";
import appwriteClient from "../appwrite.config";
import { Databases } from "appwrite";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const WorkOrderTyreUpdate = (tyreId) => {
    const [tyreIdNumber, setTyreIdNumber] = useState(tyreId.tyreId);
  const [fleetName, setFleetName] = useState("");

  const [tyreMake, setTyreMake] = useState("");
  const [tyreSize, setTyreSize] = useState("");
  const [tyreSerialNo, setTyreSerialNo] = useState("");
  const [workOrderNo, setWorkOrderNo] = useState("");
  const [remarks,setRemarks]=useState("")
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [createdBy,setCreatedBy]=useState("");

  const databases = new Databases(appwriteClient);


  useEffect(() => {
    databases
      .getDocument("AppwriteCloudFEApp", "WorkOrderTyreDetails", tyreIdNumber)
      .then((response) => {
        setFleetName(response.fleetname);
        setCreatedBy(response.createdBy);
        setRemarks(response.remarks);
        setWorkOrderNo(response.workOrderNo);
        setTyreSerialNo(response.tyreSerialNo);
        setTyreSize(response.tyreSize);
        setTyreMake(response.tyreMake);
        setDate(response.date);
        setTime(response.time);
      })
      .catch((error) => {
        toast.error("An error occurred, Please try again later");
        console.log(error);
      });
  }, []);

  const updateWorkOrder = () => {
    if(tyreIdNumber==null || fleetName =="" || date=="" || time==""){
        toast.error("Fields are empty, Please try again !!")
    }
    databases.updateDocument(
      "AppwriteCloudFEApp",
      "WorkOrderTyreDetails",
      tyreIdNumber,
      {
        date: date,
        fleetname: fleetName,
        time: time,
        workOrderNo: workOrderNo,
        tyreId:tyreIdNumber,
        tyreMake:tyreMake,
        tyreSize:tyreSize,
        tyreSerialNo:tyreSerialNo,
        remarks:remarks,
        createdBy:createdBy,


      }
    ).then((response)=>{
        toast.success("Updated Successfully");
        window.location.reload();
    }).catch((error)=>{
        toast.error("An error occurred, Please try again later");
        console.log(error)
    })
  };
  
  return (
    <div className=" w-full bg-slate-200 rounded-lg p-3 my-2 justify-center">
     
                  <ToastContainer />
      <div className="m-auto w-full justify-content-center text-center">
   
        <div className="flex flex-wrap">
            
          <div className=" flex flex-col ">
       
            <div className="">
                
              <label className="m-2 p-2 sm:float-left w-40">
              Tyre Id
              </label>
              <input
                value={tyreIdNumber}
                disabled={true}
                className="m-2 p-2 sm:float-left bg-slate-300 rounded-lg w-72 max-sm:w-4/5"
              ></input>
            </div>
            <div>
              <label className="m-2 p-2 sm:float-left w-40">Fleet Name</label>
              <input
                value={fleetName}
                onChange={(e) => {
                  setFleetName(e.target.value);
                }}
                className="m-2 p-2 bg-slate-300 sm:float-left rounded-lg w-72 max-sm:w-4/5"
              ></input>
            </div>
            <div>
              <label className="m-2 p-2 sm:float-left w-40">Tyre Make</label>
              <input
                value={tyreMake}
                onChange={(e) => {
                  setTyreMake(e.target.value);
                }}
                className="m-2 p-2 bg-slate-300 sm:float-left rounded-lg w-72 max-sm:w-4/5"
              ></input>
            </div>
            <div>
              <label className="m-2 p-2 sm:float-left w-40">Tyre Size</label>
              <input
                value={tyreSize}
                onChange={(e) => {
                  setTyreSize(e.target.value);
                }}
                className="m-2 p-2 bg-slate-300 sm:float-left rounded-lg w-72 max-sm:w-4/5"
              ></input>
            </div>

            <div>
              <label className="m-2 p-2 sm:float-left w-40">Tyre Serial No</label>
              <input
                value={tyreSerialNo}
                onChange={(e) => {
                  setTyreSerialNo(e.target.value);
                }}
                className="m-2 p-2 bg-slate-300 sm:float-left rounded-lg w-72 max-sm:w-4/5"
              ></input>
            </div>
          </div>
          <div className=" flex flex-col">
            <div>
              <label className="m-2 p-2 sm:float-left w-40">
               Work Order No
              </label>
              <input
                value={workOrderNo}
                onChange={(e) => {
                  setWorkOrderNo(e.target.value);
                }}
                className="m-2 p-2 bg-slate-300 sm:float-right rounded-lg w-72 max-sm:w-4/5"
              ></input>
            </div>
            <div>
              <label className="m-2 p-2 sm:float-left w-40">Remarks</label>
              <input
                onChange={(e) => {
                  setRemarks(e.target.value);
                }}
                value={remarks}
                className="m-2 p-2 bg-slate-300 sm:float-right rounded-lg w-72 max-sm:w-4/5"
              ></input>
            </div>
            <div>
              <label className="m-2 p-2 sm:float-left w-40">Date</label>
              <input
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                }}
                className="m-2 p-2 bg-slate-300 sm:float-right rounded-lg w-72 max-sm:w-4/5"
              ></input>
            </div>
            <div>
              <label className="m-2 p-2 sm:float-left w-40">Time</label>
              <input
                value={time}
                onChange={(e) => {
                  setTime(e.target.value);
                }}
                className="m-2 p-2 bg-slate-300 sm:float-right rounded-lg w-72 max-sm:w-4/5"
              ></input>
            </div>

            <div className="max-sm:flex-col flex m-auto">
              <button className="bg-blue-400 p-3 py-2 text-white hover:border-blue-400 hover:border-2 hover:bg-slate-200 hover:m-1 hover:text-black  m-2 rounded-lg" onClick={updateWorkOrder} >Update</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WorkOrderTyreUpdate