import React, { useEffect, useState } from 'react'
import { Databases, Query }  from 'appwrite'
import { ToastContainer, toast } from 'react-toastify'
import appwriteClient from '../appwrite.config'
import 'react-toastify/dist/ReactToastify.css'
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import { Link } from 'react-router-dom';
import { IoMdClose } from "react-icons/io";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; 

function PickupSiteTable() {

  const [columns,setColumns]=useState([
    {
      title:"Action",
      key:"action"
    },
    {
      title:"Pickup Site",
      key:"pickupSiteName"
    },
    {
      title:"Pickup Address",
      key:"pickupAddress"
    },
    {
      title:"Distance",
      key:"distance"
    },
])


const [loading,setLoading]=useState(false);
const [pickupSite,setPickupSite]=useState([]);
const databases = new Databases(appwriteClient);
const [itemsPerPage,setItemsPerPage]=useState(10);
const [totalPages,setTotalPages]=useState();
const [lastId,setLastId]=useState("");
const [currentPage, setCurrentPage] = useState(1);



const deletePickupSite=(id)=>{
  if(id){
    confirmAlert({
      title: 'Confirm to Delete ',
      message: 'Are you sure to delete this Pickup Site ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            databases.deleteDocument('AppwriteCloudFEApp',
            '65ea946debea533b0667',
            id
            ).then((respose)=>{
                           
              toast.success("Deleted Successfully");
              window.location.reload();
            }).catch((error)=>{
              toast.error("An error occurred, Please try again later");
            })
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }
}



const handleChangePage = (page) => {
  if (page >= 1 && page <= totalPages) {
    setCurrentPage(page);
  
  }
};

useEffect(()=>{
  setLoading(true)
  databases.listDocuments(
    'AppwriteCloudFEApp',
    '65ea946debea533b0667',
    [
      Query.orderAsc('pickupSiteName'), 
    
      Query.limit(itemsPerPage),
      (currentPage>1)?Query.cursorAfter(lastId):Query.offset(0),
    ]
  ).then((response)=>{
    setLastId(response.documents[response.documents.length - 1].$id);
    if(currentPage===1){setTotalPages(Math.ceil(response.total / itemsPerPage))};
    if(currentPage===1){
      setPickupSite(response.documents)
      }
      else{
           setPickupSite([...pickupSite,...response.documents])
      }
   setLoading(false)
  }).catch((error)=>{
    toast.error("Error fetching data, Please try again later !!");
    console.log(error);
    setLoading(false)
  })
},[itemsPerPage, currentPage])

const [filterText, setFilterText] = useState({});

const handleFilterChange = (key, value) => {

  setFilterText({ ...filterText, [key]: value });
  
  
};


const applyFilters = (row) => {
    
  return Object.entries(filterText).every(([key, values]) => {
    const cellValue = row[key]?row[key].toString().toLowerCase():"";
    
    return values.some(filterValue => cellValue.includes(filterValue?filterValue.toLowerCase():""));
  }
)};

const filteredData = pickupSite.filter(row => applyFilters(row));

const renderTableHeaders = () => {

  return columns.map((col, index) => (
    <th key={index}>
      <div className='p-1 flex-col flex'>
      {col.title}{''}
      {(col.key!="action")?
      <input
        type="text"
        className='p-2 rounded-md text-black'
        value={filterText[col.key] || ''}
        onChange={(e) => handleFilterChange(col.key, e.target.value.split(','))}
        placeholder={`Filter ${col.title}`}
      />:<></>}
      </div>
    </th>
  ));
};


const renderTableData = () => {
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  if(filteredData.length>=1){
  return filteredData.slice(startIndex, endIndex).map((row, index) => {

    return (
      <>
      <tr key={row.$id} className='text-center border-t-2 bg-white text-black'>
        <td>{
          <div className='flex px-3'>
          
          
          <button onClick={()=>deletePickupSite(row.$id)}>
          <MdDeleteOutline className='mx-2 hover:cursor-pointer' color='orange' size={25} />
          </button>
        </div>
        }</td>
        {columns.map((col, colIndex) => (
          (col.key!=="action")?<td key={colIndex}>{
            row[col.key]}</td>:""
        ))}
      </tr>
</>
)
})
}
}


  return (
    <div className='p-3'>
    <ToastContainer/>
    <div className='overflow-x-scroll '>
    {!loading&&
   
    <table className="table  w-full rounded-md   bg-blue-400 text-white">
      <thead>
        <tr>{renderTableHeaders()}</tr>
      </thead>
      <tbody  >{renderTableData()}</tbody>
    </table>
    
}
</div>
    <div className='m-5 flex justify-between max-sm:m-2'>
    <div>
      <button className='bg-blue-400 text-white p-2 rounded-md w-24 m-2 max-sm:m-1' onClick={() => handleChangePage(currentPage - 1)} disabled={(currentPage === 1) || loading}>
        Previous
      </button>
      </div>
      <div className='m-2 mt-4 max-sm:m-1 max-sm:mt-2'>
      <span className='m-2'>Page {currentPage} of {totalPages}</span>
      <select name="pages" id="pages" onChange={(e)=>{setItemsPerPage(e.target.value)}}>
<option value="10">10</option>
<option value="30">30</option>
<option value="50">50</option>
<option value="100">100</option>
</select>
      </div>
      <div>
      <button className='bg-blue-400 text-white p-2 rounded-md w-24 m-2 max-sm:m-1' onClick={() => handleChangePage(currentPage + 1)} disabled={(currentPage === totalPages) || loading }>
        Next
      </button>
      </div>
    </div>
  </div>
  )
}

export default PickupSiteTable