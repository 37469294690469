import React, { useState } from 'react'
import { Databases, Query , ID }  from 'appwrite'
import { ToastContainer, toast } from 'react-toastify'
import appwriteClient from '../appwrite.config'
import 'react-toastify/dist/ReactToastify.css'
import Cookies from 'js-cookie'
function TreadMakeCreation() {
    const [treadMake,setTreadMake]=useState("");
    const [treadPattern,setTreadPattern]=useState("");
    const [mileage,setMileage]=useState("");
    const [displayName,setDisplayName]=useState();
    const [otd,setOtd]=useState();
    const [images,setImages]=useState([]);

    const databases = new Databases(appwriteClient);

    const handleTreadMakeCreation=()=>{
        if(!treadMake || !displayName || !otd){
          toast.error("Some fields are empty, Please try again !!");
        }
        else{
          databases.createDocument(
            'AppwriteCloudFEApp',
            'TreadMakeandPattern',
            ID.unique(),
            {
              treadMake:treadMake,
              treadPattern:treadPattern,
              displayName:displayName,
              otd:parseFloat(otd),
              mileage:mileage,
              images:images,
              createdAt:new Date().toLocaleString(),
              createdBy:Cookies.get("userName")?Cookies.get("userName"):"Test"
    
            }
          ).then((response)=>{
            toast.success("Tread Make created sucessfully");
            window.location.reload();
          }).catch((error)=>{
            toast.error("Error saving data, Please try again later !!");
            console.log(error)
          })
        }
      }
    



  return (
    <div className=''>
    <ToastContainer/>
    <div className='flex flex-wrap justify-between max-sm:justify-center'>
        <div className=' flex flex-col w-1/2 max-sm:w-full'>
            <div className='p-2 max-sm:w-full max-sm:flex  max-sm:flex-col'>
                <label className='m-2 sm:float-left'>Tread Make</label>
                <input onChange={(e)=>{setTreadMake(e.target.value);setDisplayName(e.target.value+" - "+treadPattern)}} value={treadMake} className='m-2 p-2 sm:float-right sm:w-72 rounded-lg'></input>
            </div>
            <div className='p-2 max-sm:w-full max-sm:flex max-sm:flex-col'>
                <label className='m-2 sm:float-left'>Tread Pattern</label>
                <input onChange={(e)=>{setTreadPattern(e.target.value);setDisplayName(treadMake+" - "+e.target.value)}} value={treadPattern} className='m-2 sm:float-right sm:w-72 p-2 rounded-lg'></input>
            </div>
            <div className='p-2 max-sm:w-full max-sm:flex max-sm:flex-col'>
                <label className='m-2 sm:float-left'>Mileage</label>
                <input onChange={(e)=>{setMileage(e.target.value)}} value={mileage}  className='m-2 sm:float-right sm:w-72 p-2 rounded-lg'></input>
            </div>
           
            
            
        </div>
        <div className='flex flex-col w-1/2 max-sm:w-full'>
        <div className='p-2 max-sm:w-full max-sm:flex  max-sm:flex-col'>
                <label className='m-2 align-middle sm:float-left'>Display Name</label>
                <input onChange={(e)=>{setDisplayName(e.target.value)}} value={displayName}  className='m-2 p-2 sm:float-right sm:w-72 rounded-lg'></input>
            </div>
            <div className='p-2 max-sm:w-full max-sm:flex  max-sm:flex-col'>
                <label className='m-2 align-middle sm:float-left'>ROTD</label>
                <input onChange={(e)=>{setOtd(e.target.value)}} value={otd}  className='m-2 p-2 sm:float-right sm:w-72 rounded-lg'></input>
            </div>
            <div className='p-2 max-sm:w-full max-sm:flex  max-sm:flex-col'>
                <label className='m-2 align-middle sm:float-left'>Images</label>
                <input onChange={(e)=>{setImages(e.target.value)}} value={images} type='file' className='m-2 p-2 sm:float-right sm:w-72 rounded-lg'></input>
            </div>
         
            
        </div>
    </div>
    <div>
      <button onClick={handleTreadMakeCreation} className='m-2 p-2 bg-blue-400 rounded-lg px-10 text-white hover:bg-slate-200 hover:text-black border-2 border-blue-400'>Submit</button>
    </div>
</div>
  )
}

export default TreadMakeCreation