import { Account, Databases, Query } from 'appwrite';
import React, { useEffect, useState } from 'react'
import appwriteClient from '../appwrite.config';
import { toast } from 'react-toastify';
import Navbar from '../components/Navbar';
import Select from 'react-select'


function UserActivity() {

    const [columns,setColumns]=useState([
        
        {
            title:"User Name",
              key:"userDisplayName",
              size:"12"
          },
         {
            title:"Login Date",
              key:"loginDate",
              size:"10"
          },
          {
              title:"Login Time",
              key:"loginTime",
              size:"10"
          },
          {
              title:"Logout Time",
              key:"logoutTime",
              size:"10"
          },
          {
              title:"Platform",
              key:"loginOn",
              size:"10"
          },
          
          
          ]);




    const [username,setUsername]=useState();
    const [fromDate,setFromDate]=useState();
    const [toDate,setToDate]=useState();
    const [loginRecords,setLoginRecords]=useState([])
    const [data,setData]=useState([]);
    const [usernameDropdown,setUsernameDropdown]=useState([]);
    const [loading,setLoading]=useState();
    const databases = new Databases(appwriteClient);
    var filteredData;
    const [totalPages,setTotalPages]=useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows,setTotalRows]=useState(0);
    const [lastId,setLastId]=useState("");
    const [itemsPerPage,setItemsPerPage]=useState(10);
    const [pageNo,setPageNo]=useState(1);
    const [date,setDate]=useState("2024/07/07");




    useEffect(()=>{
        databases.listDocuments(
            'AppwriteCloudFEApp',
            'UserRegister',
            [
                Query.limit(500)
            ]
        ).then((response)=>{
            const dropdownarr=[];
            response.documents.map((data)=>{
                dropdownarr.push( {value:data.displayName,label:data.displayName})
            })
            console.log(dropdownarr)
            setUsernameDropdown(dropdownarr)
        }).catch((error)=>{

        })
    },[])

    const getLoginRecords=()=>{
        if(!username || !fromDate || !toDate){
            toast.error("Some fields are empty, Please try again !!")
        }
        else{
            if(loginRecords.length<itemsPerPage*currentPage){
            setLoading(true);
        databases.listDocuments(
            'AppwriteCloudFEApp',
            'LoginRecords',
            [
                 Query.equal('userDisplayName',username),
                 Query.greaterThanEqual('loginDate',fromDate),
                 Query.lessThanEqual('loginDate',toDate),
                 Query.orderDesc('$createdAt'),
                 Query.limit(itemsPerPage),
                 currentPage!==1?Query.cursorAfter(lastId):Query.offset(0)
            ]
        ).then((response)=>{
            console.log(response.documents)
            setLastId(response.documents[response.documents.length - 1]?.$id);
            if(currentPage===1){setTotalRows(response.total)};
             (currentPage===1)?setLoginRecords(response.documents):
             setLoginRecords([...loginRecords,...response.documents]);
              
              setLoading(false)
          
           
        }).catch((error)=>{
                toast.error('An error occurred while getting user data, Please try again')
        })
    }
    }
    }

    useEffect(()=>{
        if(username){
        getLoginRecords();
        }
    },[currentPage,itemsPerPage])
   

    const [timestamp, setTimestamp] = useState(null);
    const [nextDayTimestamp, setNextDayTimestamp] = useState(null);

    useEffect(() => {
        let myDate = [];
        let newDate;
    
        if (date) {
          myDate = date.split('/');
          const datePattern = /^\d{4}\/\d{2}\/\d{2}$/;
          if (!datePattern.test(date)) {
            console.log('Invalid date format. Please use yyyy/mm/dd');
            return;
          }
    
          var newDateTemp = new Date(
            parseInt(myDate[0]), // year
            parseInt(myDate[1]) - 1, // month (0-indexed)
            parseInt(myDate[2]) // day
          );
          newDate = newDateTemp.getTime();
    
          if (isNaN(newDate)) {
            console.log('Invalid date. Please provide a valid date in yyyy/mm/dd format.');
            return;
          }
    
          setTimestamp(newDateTemp.toISOString());
    
          // Calculate the timestamp for the next day
          newDateTemp.setDate(newDateTemp.getDate() + 1);
        
          setNextDayTimestamp(newDateTemp.toISOString());
    
          console.log(newDateTemp.toISOString());
        }
      }, [date]);
   


    const fleetProfileCreation=()=>{

        setLoading(true);
        databases.listDocuments(
            'AppwriteCloudFEApp',
            '65ea96670e2c9740a984',
            [
                Query.greaterThanEqual("$createdAt",timestamp),
                Query.lessThanEqual("$createdAt",nextDayTimestamp),
                Query.equal("createdBy",username),
                Query.limit(500)
            ]
        ).then((response)=>{
            const fleetProfileArr  = [];
            response.documents.map((doc) => {
            fleetProfileArr.push(
                {
                    type:"Fleet Profile",
                    serialNo:"",
                    fleetname:doc.fleetname,
                    vehicleRegNo:"",
                    tyreSerialNo:"",
                    createdBy:doc.createdBy,
                    createdOn:doc.createdOn,
                    timestamp:doc.$timestamp
                }
            )
            })

            setData([...data,fleetProfileArr])

            setLoading(false);
            console.log(response.documents)
        }).catch((error)=>{
            setLoading(false);
            console.log(error)
        })
    }
    const vehicleProfileCreation=()=>{

        setLoading(true);
       
        databases.listDocuments(
            'AppwriteCloudFEApp',
            'VehicleProfile',
            [
                Query.greaterThanEqual("$createdAt",timestamp),
                Query.lessThanEqual("$createdAt",nextDayTimestamp),
                Query.equal("createdBy",username),
                Query.limit(500)
            ]
        ).then((response)=>{
            const vehicleProfileArr=[];
            response.documents.map((doc) => {
                vehicleProfileArr.push({
                    type:"Vehicle Profile",
                    serialNo:"",
                    fleetname:doc.fleetname,
                    vehicleRegNo:doc.vehicleRegistrationNumber,
                    tyreSerialNo:"",
                    createdBy:doc.createdBy,
                    createdOn:doc.createdOn,
                    timestamp:doc.$timestamp
                })
            })
            setData([...data,vehicleProfileArr]);
            setLoading(false);
        }).catch((error)=>{
            console.log(error)
            setLoading(false);
        })
    }
    
   
    const tyreFitment =()=>{
        setLoading(true);
       
        databases.listDocuments(
            'AppwriteCloudFEApp',
            'FitmentData',
            [
                Query.greaterThanEqual("$createdAt",timestamp),
                Query.lessThanEqual("$createdAt",nextDayTimestamp),
                Query.equal("createdBy",username),
                Query.limit(500)
            ]
        ).then((response)=>{
            const fitmentDataArr=[];
            response.documents.map((doc) => {
                fitmentDataArr.push({
                    type:"Tyre Fitment",
                    serialNo:"",
                    fleetname:doc.fleetName,
                    vehicleRegNo:doc.vehicleRegistrationNumber,
                    tyreSerialNo:doc.tyreSerialNumber,
                    createdBy:doc.createdBy,
                    createdOn:doc.createdOn,
                    timestamp:doc.$timestamp
                })
            })
            setData([...data,fitmentDataArr]);
            setLoading(false);
        }).catch((error)=>{
            console.log(error)
            setLoading(false);
        })
    }
    const tyreTracking=()=>{
        setLoading(true);
       
        databases.listDocuments(
            'AppwriteCloudFEApp',
            'TrackingData',
            [
                Query.greaterThanEqual("$createdAt",timestamp),
                Query.lessThanEqual("$createdAt",nextDayTimestamp),
                Query.equal("createdBy",username),
                Query.limit(500)
            ]
        ).then((response)=>{
            const trackingDataArr=[];
            response.documents.map((doc) => {
                trackingDataArr.push({
                    type:"Tyre Tracking",
                    serialNo:"",
                    fleetname:doc.fleetName,
                    vehicleRegNo:doc.vehicleRegistrationNumber,
                    tyreSerialNo:doc.tyreSerialNumber,
                    createdBy:doc.createdBy,
                    createdOn:doc.createdAt,
                    timestamp:doc.$timestamp
                })
            })
            setData([...data,trackingDataArr]);
            setLoading(false);
        }).catch((error)=>{
            console.log(error)
            setLoading(false);
        })
    }
    const tyreRemoval=()=>{
        setLoading(true);
       
        databases.listDocuments(
            'AppwriteCloudFEApp',
            'RemovalData',
            [
                Query.greaterThanEqual("$createdAt",timestamp),
                Query.lessThanEqual("$createdAt",nextDayTimestamp),
                Query.equal("createdBy",username),
                Query.limit(500)
            ]
        ).then((response)=>{
            const removalDataArr=[];
            response.documents.map((doc) => {
                removalDataArr.push({
                    type:"Tyre Removal",
                    serialNo:"",
                    fleetname:doc.fleetName,
                    vehicleRegNo:doc.vehicleRegistrationNumber,
                    tyreSerialNo:doc.tyreSerialNumber,
                    createdBy:doc.createdBy,
                    createdOn:doc.createdAt,
                    timestamp:doc.$timestamp
                })
            })
            setData([...data,removalDataArr]);
            setLoading(false);
        }).catch((error)=>{
            console.log(error)
            setLoading(false);
        })
    }
    const workOrderCreation=()=>{
        setLoading(true);
       
        databases.listDocuments(
            'AppwriteCloudFEApp',
            'WorkOrder',
            [
                Query.greaterThanEqual("$createdAt",timestamp),
                Query.lessThanEqual("$createdAt",nextDayTimestamp),
                Query.equal("createdBy",username),
                Query.limit(500)
            ]
        ).then((response)=>{
            const workOrderArr=[];
            response.documents.map((doc) => {
                workOrderArr.push({
                    type:"Work Order",
                    serialNo:"",
                    fleetname:doc.fleetName,
                    vehicleRegNo:doc.vehicleRegistrationNumber,
                    tyreSerialNo:doc.tyreSerialNumber,
                    createdBy:doc.createdBy,
                    createdOn:doc.createdOn,
                    timestamp:doc.$timestamp
                })
            })
            setData([...data,workOrderArr]);
            setLoading(false);
        }).catch((error)=>{
            console.log(error)
            setLoading(false);
        })
    }
    const pickupRequestCreation=()=>{
        setLoading(true);
       
        databases.listDocuments(
            'AppwriteCloudFEApp',
            'PickupRequest',
            [
                Query.greaterThanEqual("$createdAt",timestamp),
                Query.lessThanEqual("$createdAt",nextDayTimestamp),
                Query.equal("createdBy",username),
                Query.limit(500)
            ]
        ).then((response)=>{
            const pickupRequestArr=[];
            response.documents.map((doc) => {
                pickupRequestArr.push({
                    type:"Pickup Request",
                    serialNo:doc.pickupRequestNumber,
                    fleetname:doc.fleetName,
                    vehicleRegNo:"",
                    tyreSerialNo:"",
                    createdBy:doc.createdBy,
                    createdOn:doc.createdOn,
                    timestamp:doc.$timestamp
                })
            })
            setData([...data,pickupRequestArr]);
            setLoading(false);
        }).catch((error)=>{
            console.log(error)
            setLoading(false);
        })
    }

    useEffect(()=>{
       
            setData([]);
        fleetProfileCreation();
        vehicleProfileCreation();
        tyreFitment();
        tyreTracking();
        tyreRemoval();
        workOrderCreation();
        pickupRequestCreation();
        console.log(data)
        
    },[date,username])



    const [filterText, setFilterText] = useState({});

const handleFilterChange  =  (key, value) => {
 
    setFilterText(()=>{return { ...filterText, [key]: value }});
   
   
   
 };

 const applyFilters = (row) => {

    return Object.entries(filterText).every(([key, values]) => {
      
      const cellValue = row[key].toLowerCase();
 
      return values.some(filterValue =>{ if(filterValue){return cellValue===(filterValue.toLowerCase())}return true} );
    }
 );};

 
 filteredData = loginRecords.filter(row => applyFilters(row));
 useEffect(()=>{
   if(totalRows){
   setTotalPages( Math.ceil(totalRows / itemsPerPage))
 }
 },[totalPages,filteredData,itemsPerPage,currentPage])
 
 const handleChangePage = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    
    }
  };


  const renderTableData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
       
    if(filteredData.length>=1){
      
    return filteredData.slice(startIndex, endIndex).map((row, index) => {

      return (
        <>
        <tr key={row.$id} className='text-center border-t-2 bg-white text-black'>
          
          {columns.map((col, colIndex) => (
            (col.key!="userProviderUid")?<td key={colIndex}>{row[col.key]}</td>:<td style={{maxWidth:"20px",overflow:"hidden"}} key={colIndex}>{row[col.key]}</td>
          ))}
        </tr>
        </>
      );
    });}
  };

  const renderTableHeaders = () => {

    return columns.map((col, index) => (
      <th key={index}>
        <div className='p-1'>
        {col.title}{''}
        {(col.key!="action")?
        <input
          type="text"
          className='p-2 rounded-md text-black'
          value={filterText[col.key] || ''}
          size={col.size}
          onChange={(e) => handleFilterChange(col.key, e.target.value.split(','))}
          placeholder={`Filter ${col.title}`}
        />:<></>}
        </div>
      </th>
    ));
  };



  return (
    <>
    <Navbar page={"User Activity"}/>
    <div className='m-3 p-3 flex max-sm:flex max-sm:flex-col max-sm:justify-center max-sm:align-middle bg-slate-300 rounded-lg'>
 <div className='w-64 max-sm:w-full max-sm:my-1'>
    <Select  
  isMulti={false}
  name="username"
  options={usernameDropdown}
  className="basic-multi-select"
  classNamePrefix="select"
  placeholder="Select Username"
  
  onChange={(e)=>{setUsername(e.value);}}
/>
</div>
<div className='ml-3 h-9 flex max-sm:w-full max-sm:my-1 flex-row max-sm:m-1 max-sm:ml-0 justify-between align-middle  '>
<label className='h-8 flex flex-col align-middle m-2 mt-2 '>From Date</label>
<input className='rounded-sm px-2' onChange={(e)=>{setFromDate(e.target.value.replace("-","/").replace("-","/"))}} type='date'></input>
</div>
<div className='ml-3 h-9 flex flex-row max-sm:m-1 max-sm:ml-0 justify-between  align-middle  '>
<label className='h-8 flex flex-col align-middle m-2 mt-2 '>To Date </label>
<input className='rounded-sm px-2' onChange={(e)=>{setToDate(e.target.value.replace("-","/").replace("-","/"));console.log(e.target.value.replace("-","/").replace("-","/"))}} type='date'></input>
</div>
<div className='flex mx-3 max-sm:m-auto max-sm:my-2'>
<button className='p-1 px-2 rounded-lg text-white hover:bg-slate-300 hover:text-black border-2 border-blue-400 bg-blue-400' onClick={getLoginRecords}>Get Data</button>
</div>
    </div>

    <div className='flex flex-row flex-wrap'>
    <div className='w-1/2'>
            {
                !loginRecords.length>0? <div className='text-center mt-5'>No data to show</div>:
                <div></div>
            }
            {loginRecords.length>0&&
            <>
                <div className='overflow-x-scroll m-3'>
                {!loading&&
               
                <table className="table   w-full rounded-md  bg-blue-400 text-white">
                  <thead className='sticky top-0'>
                    <tr>{renderTableHeaders()}</tr>
                  </thead>
                  <tbody  className='h-4/5 overflow-scroll'>{renderTableData()}</tbody>
                </table>
                
           }
           </div>
            
              <div className='m-5 flex justify-between max-sm:m-2'>
                <div>
                  <button className='bg-blue-400 text-white p-2 rounded-md w-24 m-2 max-sm:m-1' onClick={() => handleChangePage(currentPage - 1)} disabled={(currentPage === 1) || loading}>
                    Previous
                  </button>
                  </div>
                  <div className='m-2 mt-4 max-sm:m-1 max-sm:mt-2'>
                  <span className='m-2'>Page {currentPage} of {totalPages}</span>
                  <select name="pages" id="pages" onChange={(e)=>{setItemsPerPage(e.target.value)}}>
            <option value="10">10</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
                  </div>
                  <div>
                  <button className='bg-blue-400 text-white p-2 rounded-md w-24 m-2 max-sm:m-1' onClick={() => handleChangePage(currentPage + 1)} disabled={(currentPage === totalPages) || loading }>
                    Next
                  </button>
                  </div>
                </div>
                </>
            }
        </div>
        <div className='w-1/2'>
            <table>

            </table>
        </div>
        </div>

    </>
  )
}

export default UserActivity