import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar';
import { Collapse } from 'react-collapse';
import Select from 'react-select'
import { Databases, Query } from 'appwrite';
import { TbFilterCog } from "react-icons/tb";
import { ToastContainer, toast } from 'react-toastify'
import XLSX from 'xlsx';
import * as FileSaver from 'file-saver'
import appwriteClient from '../appwrite.config';

const VehicleProfile = () => {
  const [isFilterOptionVisible,setIsFilterOptionVisible]=useState(true);
  const [fleetNameDropdown,setFleetNameDropdown]=useState([]);
  const [vehicleDropdown,setVehicleDropdown]=useState([]);
  const [selectedFleetname,setSelectedFleetname]=useState([]);
  const [selectedVehicles,setSelectedVehicles]=useState([]);
  const [vehicleData,setVehicleData]=useState([]);
  const [tyreSerialNumber,setTyreSerialNumber]=useState([]);
  const [lastTrackingData,setLastTrackingData]=useState([]);
  
  const [vehicleMap,setVehicleMap]=useState({});
  const [loading,setLoading]=useState(false);
  const databases = new Databases(appwriteClient);
  const [currentPage,setCurrentPage]=useState(0);
  const [totalPages,setTotalPages]=useState(0);
  const [exportDataState,setExportDataState]=useState([]);
  const exportData = [];
  const uniqueVehicleNumber = [];
  useEffect(()=>{
    databases.listDocuments(
        'AppwriteCloudFEApp',
        '65ea96670e2c9740a984',
        [
            Query.limit(500),
            
        ]
    ).then((response)=>{
        const fleetNameDropdownarr=[];
        response.documents.map((data)=>{
            fleetNameDropdownarr.push(
            {value:data.fleetname,label:data.fleetname}
          ) 
        })
        setFleetNameDropdown(fleetNameDropdownarr);
    }).catch((error)=>{
        toast.error("An error occurred while getting Fleetname dropdown, Please try again later !!");
        console.log(error);
    })
},[])



const fileType='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8';
      
const fileExtension = '.xlsx';

const exportToExcel = async () => {
  setExportDataState((prev)=>[...prev,...exportData])
const ws = XLSX.utils.json_to_sheet (exportDataState);

const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array' });

const data = new Blob ([excelBuffer], { type: fileType });

FileSaver.saveAs (data, "Fitment Data "+selectedFleetname + fileExtension);
}
  

const pushTwoEmptyRow = ()=>{
  exportData.push(exportData.push({
    "S. NO": "",
    "Fleet Name": "",
    "Vehicle Reg. No.": "",
    "Vehicle Type": "",
    "Position": "",
    "Avg. Run": "",
    "Tyre Status": "",
    "Tyre MnP": "",
    "Tread MnP": "",
    "Tyre SIze": "",
    "Tyre Serial No.": "",
    "OTD": "",
    "Fitment Date": "",
    "Fitment ODO": "",
    "RTD": "",
    "Tracking Date": "",
    "Tracking ODO": "",
    "Run. Till Date": "",
    "Total Run.(Projected)": "",
    "Removal Date (Projected)": "",
    "Remarks": ""
}));
exportData.push(exportData.push({
  "S. NO": "",
  "Fleet Name": "",
  "Vehicle Reg. No.": "",
  "Vehicle Type": "",
  "Position": "",
  "Avg. Run": "",
  "Tyre Status": "",
  "Tyre MnP": "",
  "Tread MnP": "",
  "Tyre SIze": "",
  "Tyre Serial No.": "",
  "OTD": "",
  "Fitment Date": "",
  "Fitment ODO": "",
  "RTD": "",
  "Tracking Date": "",
  "Tracking ODO": "",
  "Run. Till Date": "",
  "Total Run.(Projected)": "",
  "Removal Date (Projected)": "",
  "Remarks": ""
}));
uniqueVehicleNumber.push(vehicleData[currentPage].vehicleRegistrationNumber)
}

  useEffect(()=>{
    if(selectedFleetname.length>0){
        
     databases.listDocuments(
         'AppwriteCloudFEApp',
         'VehicleProfile',
         [
             Query.equal("fleetname",selectedFleetname),
             Query.limit(1000)
             
         ]
     ).then((response)=>{
         const vehicleDropdownArr=[]
        
         response.documents.map((data)=>{
            
           vehicleDropdownArr.push(
             {value:data.vehicleRegistrationNumber,label:data.vehicleRegistrationNumber}
           ) 
         })
 
         setVehicleDropdown(vehicleDropdownArr)
     }).catch((error)=>{
         toast.error("An error occurred while getting Vehicle dropdown, Please try again later !!");
         console.log(error);
     })
    }
 },[selectedFleetname])




  const handleSubmit = () =>{

    if(selectedFleetname.length<1){
      toast.error("Please select atleat one Fleet Name to continue !!");
    }
    else if(selectedVehicles.length<1){
      toast.error("Please select atleat one Vehicle to continue !!");
    }
    else{
      setLoading(true)
      databases.listDocuments(
        'AppwriteCloudFEApp',
        'VehicleProfile',
        [
          Query.equal("vehicleRegistrationNumber",selectedVehicles),
          Query.limit(1000),
          Query.orderDesc("$updatedAt")
        ]
      ).then((response)=>{
        setVehicleData(response.documents);
        setTotalPages(response.total);
        setLoading(false)
      }).catch((error)=>{
        toast.error("Error getting Vehicle data, Please try again !!")
        setLoading(false)
        console.log(error)
      })
    }
  }

  useEffect(()=>{
    console.log(vehicleData)
    if(vehicleData.length>0){
      setTyreSerialNumber([]);
      const data = vehicleData[currentPage];
   
      if(data.vehicleType==="12W Truck"){
        if(data.FSH!=="" || data.FSH!==null ){
          setTyreSerialNumber((prev)=>[...prev,data.FSH])
        }
        if(data.FSD!=="" || data.FSD!==null){
          setTyreSerialNumber((prev)=>[...prev,data.FSD])
        }
        if(data.SSH!=="" || data.SSH!==null){
          setTyreSerialNumber((prev)=>[...prev,data.SSH])
        }
        if(data.SSD!=="" || data.SSD!==null){
          setTyreSerialNumber((prev)=>[...prev,data.SSD])
        }
        if(data.CHO!=="" || data.CHO!==null){
          setTyreSerialNumber((prev)=>[...prev,data.CHO])
        }
        if(data.CHI!=="" || data.CHI!==null){
          setTyreSerialNumber((prev)=>[...prev,data.CHI])
        }
        if(data.CDO!=="" || data.CDO!==null){
          setTyreSerialNumber((prev)=>[...prev,data.CDO])
        }
        if(data.CDI!=="" || data.CDI!==null){
          setTyreSerialNumber((prev)=>[...prev,data.CDI])
        }
        if(data.DHO!=="" || data.DHO!==null){
          setTyreSerialNumber((prev)=>[...prev,data.DHO])
        }
        if(data.DHI!=="" || data.DHI!==null){
          setTyreSerialNumber((prev)=>[...prev,data.DHI])
        }
        if(data.DDO!=="" || data.CDO!==null){
          setTyreSerialNumber((prev)=>[...prev,data.DDO])
        }
        if(data.DDI!=="" || data.DDI!==null){
          setTyreSerialNumber((prev)=>[...prev,data.DDI])
        }
        if(data.STA!=="" || data.STA!==null){
          setTyreSerialNumber((prev)=>[...prev,data.STA])
        }
      }


      if(data.vehicleType==="14W L1 Truck"){
        if(data.FSH!=="" || data.FSH!==null ){
          setTyreSerialNumber((prev)=>[...prev,data.FSH])
        }
        if(data.FSD!=="" || data.FSD!==null){
          setTyreSerialNumber((prev)=>[...prev,data.FSD])
        }
        if(data.SSH!=="" || data.SSH!==null){
          setTyreSerialNumber((prev)=>[...prev,data.SSH])
        }
        if(data.SSD!=="" || data.SSD!==null){
          setTyreSerialNumber((prev)=>[...prev,data.SSD])
        }
        if(data.L1H!=="" || data.L1H!==null){
          setTyreSerialNumber((prev)=>[...prev,data.L1H])
        }
        if(data.L1D!=="" || data.L1D!==null){
          setTyreSerialNumber((prev)=>[...prev,data.L1D])
        }
        if(data.CHO!=="" || data.CHO!==null){
          setTyreSerialNumber((prev)=>[...prev,data.CHO])
        }
        if(data.CHI!=="" || data.CHI!==null){
          setTyreSerialNumber((prev)=>[...prev,data.CHI])
        }
        if(data.CDO!=="" || data.CDO!==null){
          setTyreSerialNumber((prev)=>[...prev,data.CDO])
        }
        if(data.CDI!=="" || data.CDI!==null){
          setTyreSerialNumber((prev)=>[...prev,data.CDI])
        }
        if(data.DHO!=="" || data.DHO!==null){
          setTyreSerialNumber((prev)=>[...prev,data.DHO])
        }
        if(data.DHI!=="" || data.DHI!==null){
          setTyreSerialNumber((prev)=>[...prev,data.DHI])
        }
        if(data.DDO!=="" || data.CDO!==null){
          setTyreSerialNumber((prev)=>[...prev,data.DDO])
        }
        if(data.DDI!=="" || data.DDI!==null){
          setTyreSerialNumber((prev)=>[...prev,data.DDI])
        }
        if(data.STA!=="" || data.STA!==null){
          setTyreSerialNumber((prev)=>[...prev,data.STA])
        }
      }


      if(data.vehicleType==="14W L2 Truck"){
        if(data.FSH!=="" || data.FSH!==null ){
          setTyreSerialNumber((prev)=>[...prev,data.FSH])
        }
        if(data.FSD!=="" || data.FSD!==null){
          setTyreSerialNumber((prev)=>[...prev,data.FSD])
        }
        if(data.SSH!=="" || data.SSH!==null){
          setTyreSerialNumber((prev)=>[...prev,data.SSH])
        }
        if(data.SSD!=="" || data.SSD!==null){
          setTyreSerialNumber((prev)=>[...prev,data.SSD])
        }
        if(data.L2H!=="" || data.L2H!==null){
          setTyreSerialNumber((prev)=>[...prev,data.L2H])
        }
        if(data.L2D!=="" || data.L2D!==null){
          setTyreSerialNumber((prev)=>[...prev,data.L2D])
        }
        if(data.CHO!=="" || data.CHO!==null){
          setTyreSerialNumber((prev)=>[...prev,data.CHO])
        }
        if(data.CHI!=="" || data.CHI!==null){
          setTyreSerialNumber((prev)=>[...prev,data.CHI])
        }
        if(data.CDO!=="" || data.CDO!==null){
          setTyreSerialNumber((prev)=>[...prev,data.CDO])
        }
        if(data.CDI!=="" || data.CDI!==null){
          setTyreSerialNumber((prev)=>[...prev,data.CDI])
        }
        if(data.DHO!=="" || data.DHO!==null){
          setTyreSerialNumber((prev)=>[...prev,data.DHO])
        }
        if(data.DHI!=="" || data.DHI!==null){
          setTyreSerialNumber((prev)=>[...prev,data.DHI])
        }
        if(data.DDO!=="" || data.CDO!==null){
          setTyreSerialNumber((prev)=>[...prev,data.DDO])
        }
        if(data.DDI!=="" || data.DDI!==null){
          setTyreSerialNumber((prev)=>[...prev,data.DDI])
        }
        if(data.STA!=="" || data.STA!==null){
          setTyreSerialNumber((prev)=>[...prev,data.STA])
        }
      }


        if(data.vehicleType==="16W L1(2) Truck"){
          if(data.FSH!=="" || data.FSH!==null ){
            setTyreSerialNumber((prev)=>[...prev,data.FSH])
          }
          if(data.FSD!=="" || data.FSD!==null){
            setTyreSerialNumber((prev)=>[...prev,data.FSD])
          }
          if(data.SSH!=="" || data.SSH!==null){
            setTyreSerialNumber((prev)=>[...prev,data.SSH])
          }
          if(data.SSD!=="" || data.SSD!==null){
            setTyreSerialNumber((prev)=>[...prev,data.SSD])
          }
          if(data.L1HO!=="" || data.L1HO!==null){
            setTyreSerialNumber((prev)=>[...prev,data.L1HO])
          }
          if(data.L1HI!=="" || data.L1HI!==null){
            setTyreSerialNumber((prev)=>[...prev,data.L1HI])
          }
          if(data.L1DO!=="" || data.L1DO!==null){
            setTyreSerialNumber((prev)=>[...prev,data.L1DO])
          }
          if(data.L1DI!=="" || data.L1DI!==null){
            setTyreSerialNumber((prev)=>[...prev,data.L1DI])
          }
          if(data.CHO!=="" || data.CHO!==null){
            setTyreSerialNumber((prev)=>[...prev,data.CHO])
          }
          if(data.CHI!=="" || data.CHI!==null){
            setTyreSerialNumber((prev)=>[...prev,data.CHI])
          }
          if(data.CDO!=="" || data.CDO!==null){
            setTyreSerialNumber((prev)=>[...prev,data.CDO])
          }
          if(data.CDI!=="" || data.CDI!==null){
            setTyreSerialNumber((prev)=>[...prev,data.CDI])
          }
          if(data.DHO!=="" || data.DHO!==null){
            setTyreSerialNumber((prev)=>[...prev,data.DHO])
          }
          if(data.DHI!=="" || data.DHI!==null){
            setTyreSerialNumber((prev)=>[...prev,data.DHI])
          }
          if(data.DDO!=="" || data.CDO!==null){
            setTyreSerialNumber((prev)=>[...prev,data.DDO])
          }
          if(data.DDI!=="" || data.DDI!==null){
            setTyreSerialNumber((prev)=>[...prev,data.DDI])
          }
          if(data.STA!=="" || data.STA!==null){
            setTyreSerialNumber((prev)=>[...prev,data.STA])
          }
        }
      
      console.log(tyreSerialNumber)
    }
  },[vehicleData,currentPage])

useEffect(()=>{
if(tyreSerialNumber.length>0){
  setLoading(true)
  databases.listDocuments(
    'AppwriteCloudFEApp',
        'LastTrackingData',
        [
          Query.equal("tyreSerialNumber",tyreSerialNumber),
          Query.limit(1000),
          Query.orderDesc("$updatedAt"),
          Query.orderAsc("vehicleRegistrationNumber")
        ]
  ).then((response)=>{
    setLastTrackingData(response.documents)
    console.log(lastTrackingData)
    setLoading(false)
    setIsFilterOptionVisible(false);
  }).catch((error)=>{
    toast.error("Error getting Last Tracking Data, Please try again later !!")
    console.log(error)
    setLoading(false)
    
  })
}
},[tyreSerialNumber])


// useEffect(() => {
//   const groupByVehicle = lastTrackingData.reduce((acc, current) => {
//     const vehicleNumber = current.vehicleRegistrationNumber;
//     if (!acc[vehicleNumber]) {
//       acc[vehicleNumber] = [];
//     }
//     acc[vehicleNumber].push(current);
//     return acc;
//   }, {});

//   setVehicleMap(groupByVehicle);
//   console.log(vehicleMap)
// }, [lastTrackingData]);


  return (
    <>
    <Navbar page={"Vehicle Profile"}/>
    <ToastContainer/>
    <div>
    <div className='flex flex-row justify-between p-5'>
   
   <div>
 <button onClick={()=>{exportToExcel()}} className="hover:cursor-pointer p-2 m-2 rounded-lg bg-blue-400 border-2 border-blue-400 text-white hover:bg-white hover:text-black">Export</button>
   </div>
    <div><button onClick={()=>{setIsFilterOptionVisible(!isFilterOptionVisible)}} className="hover:cursor-pointer">{isFilterOptionVisible?<TbFilterCog size={40} color='#06d65d'/>:<TbFilterCog size={40} color='#60A5FA'/>}</button></div>
   </div>
   
   <Collapse isOpened={isFilterOptionVisible}>
       
       <div className='bg-gray-200 w-4/5 m-auto justify-center p-5 rounded-2xl'>
           <div className='flex sm:flex-row sm:flex-wrap justify-center max-sm:flex-col'>
              
               <div className='sm:w-2/5 max-sm:w-full sm:m-1 p-3 text-center'>
               <label>Fleet Name</label>
               <Select
 
   isMulti
   name="fleetname"
   options={fleetNameDropdown}
   className="basic-multi-select"
   classNamePrefix="select"
   onChange={(e)=>{const selectedFleetNameArr=[];e.map((data)=>{selectedFleetNameArr.push(data.value)});setSelectedFleetname(selectedFleetNameArr)}}
 />
               </div>
               <div className='sm:w-2/5 max-sm:w-full sm:m-1 p-3 text-center'>
               <label>Vehicle Reg. No.</label>
               <Select
 
   isMulti
   name="vehicle"
   options={vehicleDropdown}
   className="basic-multi-select"
   classNamePrefix="select"
   onChange={(e)=>{const selectedVehicleArr=[];e.map((data)=>{selectedVehicleArr.push(data.value)});setSelectedVehicles(selectedVehicleArr)}}
 />
               </div>
               
           </div>
           <div className='m-auto justify-items-center w-fit justify-center'>
           <button onClick={()=>{handleSubmit()}}  className='m-auto justify-items-center bg-blue-400 text-white p-2 border-2 border-blue-400 hover:bg-slate-200 hover:text-black px-4 hover: rounded-lg'>Search</button>
           </div>
       </div>
   
       </Collapse>
       {(!isFilterOptionVisible)&&<div>
        
        <h1 className='text-center  text-2xl font-bold'>{vehicleData[currentPage].vehicleRegistrationNumber}</h1>
        <div className='overflow-x-scroll w-full '>
        <table className="table     m-3 rounded-md   bg-blue-400 text-white">
          <thead className='sticky top-0'>
<tr>
  <th classname="mx-2">S. No.</th>
  <th classname="mx-2">Fleet Name</th>
  <th classname="mx-2">Vehicle Reg. No.</th>
  <th classname="mx-2">Vehicle Type</th>
  <th classname="mx-2">Position</th>
  <th classname="mx-2">Avg. Running</th>
  <th classname="mx-2">Tyre Status</th>
  <th classname="mx-2">Tyre MnP</th>
  <th classname="mx-2">Tread MnP</th>
  <th classname="mx-2">Tyre Size</th>
  <th classname="mx-2">Tyre Serial No.</th>
  <th classname="mx-2">OTD</th>
  <th classname="mx-2">Fitment Date</th>
  <th classname="mx-2">Fitment ODO</th>
  <th classname="mx-2">RTD</th>
  <th classname="mx-2">RTD Date</th>
  <th classname="mx-2">RTD ODO</th>
  <th classname="mx-2">Run. Till Date</th>
  <th classname="mx-2">Total Run.(Projected)</th>
  <th classname="mx-2">Removal Date (Projected)</th>
  <th classname="mx-2">Remarks</th>
  <th classname="mx-2"></th>
</tr>
          </thead>
          <tbody className='h-4/5 '>
          {lastTrackingData.map((data,index)=>{
            let tdDiff = 0;
            let odoDiff = 0;
            let dateDiff = 0;
            let runTillDate = 0;
            let totalProjectedRun = 0;
            let removalProjectedDate = 0;

            if(data.ftimentTd && data.lastTrackingTD && data.lastTrackingTD<data.ftimentTd){
              tdDiff = data.ftimentTd-data.lastTrackingTD;
            }
            if(data.fitmentODO && data.lastTrackingODO && data.lastTrackingODO>data.fitmentODO){
              odoDiff=data.lastTrackingODO-data.fitmentODO;
            }
            if(tdDiff>0 && odoDiff>0){
              runTillDate=odoDiff;
              totalProjectedRun=Math.round((runTillDate/tdDiff)*data.ftimentTd);
            }
            if(!uniqueVehicleNumber.includes(vehicleData[currentPage].vehicleRegistrationNumber)){
              exportData.push({
                "S. NO": index+1,
                "Fleet Name": data.fleetName,
                "Vehicle Reg. No.":data.vehicleRegistrationNumber,
                "Vehicle Type":data.vehicleType,
                "Position":data.position,
                "Avg. Run":data.avgRun,
                "Tyre Status":data.tyreStatus,
                "Tyre MnP":data.tyreMakeandPattern,
                "Tread MnP":data.treadMakeandPattern,
                "Tyre SIze":data.tyreSize,
                "Tyre Serial No.":data.tyreSerialNumber,
                "OTD":data.ftimentTd,
                "Fitment Date":data.fitmentDate,
                "Fitment ODO":data.fitmentODO,
                "RTD":data.lastTrackingTD,
                "Tracking Date":data.lastTrackingDate,
                "Tracking ODO":data.lastTrackingODO,
                "Run. Till Date":runTillDate,
                "Total Run.(Projected)":totalProjectedRun,
                "Removal Date (Projected)":"",
                "Remarks":data.rtdRemarks

              })
            }
            if (index === lastTrackingData.length - 1) {
              
          }
          return (
            <tr>
            <td className='text-center border-t-2 px-2 bg-white text-black'>{index+1}</td>
            <td className='text-center border-t-2 px-2 bg-white text-black'>{data.fleetName}</td>
            <td className='text-center border-t-2 px-2 bg-white text-black'>{data.vehicleRegistrationNumber}</td>
            <td className='text-center border-t-2 px-2 bg-white text-black'>{data.vehicleType}</td>
            <td className='text-center border-t-2 px-2 bg-white text-black'>{data.position}</td>
            <td className='text-center border-t-2 px-2 bg-white text-black'>{data.avgRun}</td>
            <td className='text-center border-t-2 px-2 bg-white text-black'>{data.tyreStatus}</td>
            <td className='text-center border-t-2 px-2 bg-white text-black'>{data.tyreMakeandPattern}</td>
            <td className='text-center border-t-2 px-2 bg-white text-black'>{data.treadMakeandPattern}</td>
            <td className='text-center border-t-2 px-2 bg-white text-black'>{data.tyreSize}</td>
            <td className='text-center border-t-2 px-2 bg-white text-black'>{data.tyreSerialNumber}</td>
            <td className='text-center border-t-2 px-2 bg-white text-black'>{data.ftimentTd}</td>
            <td className='text-center border-t-2 px-2 bg-white text-black'>{data.fitmentDate}</td>
            <td className='text-center border-t-2 px-2 bg-white text-black'>{data.fitmentODO}</td>
            <td className='text-center border-t-2 px-2 bg-white text-black'>{data.lastTrackingTD}</td>
            <td className='text-center border-t-2 px-2 bg-white text-black'>{data.lastTrackingDate}</td>
            <td className='text-center border-t-2 px-2 bg-white text-black'>{data.lastTrackingODO}</td>
            <td className='text-center border-t-2 px-2 bg-white text-black'>{runTillDate}</td>
            <td className='text-center border-t-2 px-2 bg-white text-black'>{totalProjectedRun}</td>
            <td className='text-center border-t-2 px-2 bg-white text-black'>{data.position}</td>
            <td className='text-center border-t-2 px-2 bg-white text-black'>{data.rtdRemarks}</td>

          </tr>
        )
          
        })
        
        }
       

          </tbody>
        </table>
        </div>
        
        
        
        <p>{lastTrackingData.length}</p>
        <div className='m-5 flex justify-between max-sm:m-2'>
                <div>
                  <button className='bg-blue-400 text-white p-2 rounded-md w-24 m-2 max-sm:m-1' onClick={() => {if(currentPage>0){setCurrentPage(currentPage-1)}}} disabled={(currentPage === 0) || loading}>
                    Previous
                  </button>
                  </div>
                  <div className='m-2 mt-4 max-sm:m-1 max-sm:mt-2'>
                  <span className='m-2'>Report {currentPage+1} of {totalPages}</span>
                 
                  </div>
                  <div>
                  <button className='bg-blue-400 text-white p-2 rounded-md w-24 m-2 max-sm:m-1' onClick={() => {if(currentPage+1<totalPages){setCurrentPage(currentPage+1);pushTwoEmptyRow()}setExportDataState((prev)=>[...prev,...exportData])}} disabled={(currentPage+1 === totalPages) || loading }>
                    Next
                  </button>
                  </div>
                </div>
        </div>
        
        }


    </div>
    </>
  )
}

export default VehicleProfile
