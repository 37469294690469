import React, { useEffect, useState } from 'react'
import FleetProfileTable from '../components/FleetProfileTable';
import VehicleProfileTable from '../components/VehicleProfileTable';
import TyreMnPTable from '../components/TyreMnPTable';
import TreadMnPTable from '../components/TreadMnPTable';
import PickupSiteTable from '../components/PickupSiteTable';
import TyreSizeTable from '../components/TyreSizeTable';
import RemarksTable from '../components/RemarksTable';
import FleetProfileCreation from '../components/FleetProfileCreation';
import { IoMdClose } from "react-icons/io";
import TyreMakeCreation from '../components/TyreMakeCreation.jsx';
import PickupSiteCreation from '../components/PickupSiteCreation.jsx';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import TyreSizeCreation from '../components/TyreSizeCreation.jsx';
import Navbar from '../components/Navbar.jsx';
import TreadMakeCreation from '../components/TreadMakeCreation.jsx'

function NameList() {



    
    const navigate = useNavigate();
    
    const [activeTab,setActiveTab]=useState("FleetProfile");
    const [isFleetProfileCreationVisible,setIsFleetProfileCreationVisible]=useState(false);
    const [isTyreMakeCreationVisible,setIsTyreMakeCreationVisible]=useState(false);
    const [isPickupSiteCreationVisible,setIsPickupSiteCreationVisible]=useState(false);
    const [isTyreSizeCreationVisible,setIsTyreSizeCreationVisible]=useState(false)
    const [isTreadMakeCreationVisible,setIsTreadMakeCreationVisible]=useState(false);
  return (
    <div>
        <Navbar page={"Name List"}/>
        <div className='bg-slate-200 flex m-3 rounded-lg p-1 h-16 '>
            <div className='flex max-sm:overflow-x-scroll w-3/4'>
<button
onClick={(e)=>{setActiveTab("FleetProfile")}}
className={`${activeTab==="FleetProfile"?"bg-blue-400":""} ${activeTab==="FleetProfile"?"text-white":""} p-2 text-nowrap rounded-lg hover:text-white m-2 hover:bg-blue-400`}>
    Fleet Profile
</button>
<button 
onClick={(e)=>{setActiveTab("VehicleProfile")}}
className={`${activeTab==="VehicleProfile"?"bg-blue-400":""} ${activeTab==="VehicleProfile"?"text-white":""} p-2 text-nowrap rounded-lg hover:text-white m-2 hover:bg-blue-400`}>
    Vehicle Profile
</button>
<button
onClick={(e)=>{setActiveTab("TyreMnP")}}
className={`${activeTab==="TyreMnP"?"bg-blue-400":""} ${activeTab==="TyreMnP"?"text-white":""} p-2 text-nowrap rounded-lg hover:text-white m-2 hover:bg-blue-400`}>
    Tyre Make and Pattern
</button>
<button
onClick={(e)=>{setActiveTab("TreadMnP")}}
className={`${activeTab==="TreadMnP"?"bg-blue-400":""} ${activeTab==="TreadMnP"?"text-white":""} p-2 text-nowrap rounded-lg hover:text-white m-2 hover:bg-blue-400`}>
    Tread Make and Pattern
</button>
<button
onClick={(e)=>{setActiveTab("PickupSite")}}
className={`${activeTab==="PickupSite"?"bg-blue-400":""} ${activeTab==="PickupSite"?"text-white":""} p-2 text-nowrap rounded-lg hover:text-white m-2 hover:bg-blue-400`}>
    Pickup Site
</button>
<button
onClick={(e)=>{setActiveTab("TyreSize")}}
className={`${activeTab==="TyreSize"?"bg-blue-400":""} ${activeTab==="TyreSize"?"text-white":""} p-2 text-nowrap rounded-lg hover:text-white m-2 hover:bg-blue-400`}>
    Tyre Size
</button>
{/* <button
onClick={(e)=>{setActiveTab("Remarks")}}
className={`${activeTab==="Remarks"?"bg-blue-400":""} ${activeTab==="Remarks"?"text-white":""} p-2 text-nowrap rounded-lg hover:text-white m-2 hover:bg-blue-400`}>
    Remarks
</button> */}
            </div>
            <div className='1/4 m-auto'>
                {activeTab==="FleetProfile"?<button className=' p-2 text-nowrap rounded-lg text-white m-2 bg-blue-400 hover:bg-slate-200 hover:border-2 hover:border-blue-400 hover:text-black ' onClick={(e)=>{setIsFleetProfileCreationVisible(!isFleetProfileCreationVisible)}}>Add Fleet Profile</button>:""}
                {activeTab==="VehicleProfile"?<button className=' p-2 text-nowrap rounded-lg text-white m-2 bg-blue-400 hover:bg-slate-200 hover:border-2 hover:border-blue-400 hover:text-black ' >Add Vehicle Profile</button>:""}
                {activeTab==="TyreMnP"?<button className=' p-2 text-nowrap rounded-lg text-white m-2 bg-blue-400 hover:bg-slate-200 hover:border-2 hover:border-blue-400 hover:text-black ' onClick={(e)=>{setIsTyreMakeCreationVisible(!isTyreMakeCreationVisible)}}>Add Tyre Make</button>:""}
                {activeTab==="TreadMnP"?<button className=' p-2 text-nowrap rounded-lg text-white m-2 bg-blue-400 hover:bg-slate-200 hover:border-2 hover:border-blue-400 hover:text-black 'onClick={(e)=>{setIsTreadMakeCreationVisible(!isTreadMakeCreationVisible)}}>Add Tread Make</button>:""}
                {activeTab==="PickupSite"?<button className=' p-2 text-nowrap rounded-lg text-white m-2 bg-blue-400 hover:bg-slate-200 hover:border-2 hover:border-blue-400 hover:text-black ' onClick={(e)=>{setIsPickupSiteCreationVisible(!isPickupSiteCreationVisible)}}>Add Pickup Site</button>:""}
                {activeTab==="TyreSize"?<button className=' p-2 text-nowrap rounded-lg text-white m-2 bg-blue-400 hover:bg-slate-200 hover:border-2 hover:border-blue-400 hover:text-black ' onClick={(e)=>{setIsTyreSizeCreationVisible(!isTyreSizeCreationVisible)}}>Add Tyre Size</button>:""}
                {/* {activeTab==="Remarks"?<button className=' p-2 text-nowrap rounded-lg text-white m-2 bg-blue-400 hover:bg-slate-200 hover:border-2 hover:border-blue-400 hover:text-black '>Add Remarks</button>:""} */}
            </div>
        </div>
        <div>
            {activeTab==="FleetProfile"?<FleetProfileTable/>:""}
            {activeTab==="VehicleProfile"?<VehicleProfileTable/>:""}
            {activeTab==="TyreMnP"?<TyreMnPTable/>:""}
            {activeTab==="TreadMnP"?<TreadMnPTable/>:""}
            {activeTab==="PickupSite"?<PickupSiteTable/>:""}
            {activeTab==="TyreSize"?<TyreSizeTable/>:""}
            {/* {activeTab==="Remarks"?<RemarksTable/>:""} */}
            {
                isFleetProfileCreationVisible&&
                <div className='absolute top-28 left-44 w-3/4 max-sm:w-11/12 max-sm:left-0 max-sm:mr-3 text-center bg-slate-200 p-3 m-3 rounded-xl'>
                    <div className='text-center flex justify-center text-xl'>
                    Fleet Profile 
                    <button className='mx-4' onClick={(e)=>{setIsFleetProfileCreationVisible(!isFleetProfileCreationVisible)}}>
                    <IoMdClose size={25}/>
                    </button>
                   
                    </div>
                    <FleetProfileCreation isUpdation={false} id={""}/>
                </div>
            }
 {
                isTyreMakeCreationVisible&&
                <div className='absolute top-28 left-44 w-3/4 max-sm:w-11/12 max-sm:left-0 max-sm:mr-3 text-center bg-slate-200 p-3 m-3 rounded-xl'>
                    <div className='text-center flex justify-center text-xl'>
                    Tyre Make and Pattern 
                    <button className='mx-4' onClick={(e)=>{setIsTyreMakeCreationVisible(!isTyreMakeCreationVisible)}}>
                    <IoMdClose size={25}/>
                    </button>
                   
                    </div>
                    <TyreMakeCreation/>
                                    </div>
            }
             {
                isTreadMakeCreationVisible&&
                <div className='absolute top-28 left-44 w-3/4 max-sm:w-11/12 max-sm:left-0 max-sm:mr-3 text-center bg-slate-200 p-3 m-3 rounded-xl'>
                    <div className='text-center flex justify-center text-xl'>
                    Tread Make and Pattern 
                    <button className='mx-4' onClick={(e)=>{setIsTreadMakeCreationVisible(!isTreadMakeCreationVisible)}}>
                    <IoMdClose size={25}/>
                    </button>
                   
                    </div>
                    <TreadMakeCreation/>
                                    </div>
            }
             {
                isPickupSiteCreationVisible&&
                <div className='absolute top-28 left-44 w-3/4 max-sm:w-11/12 max-sm:left-0 max-sm:mr-3 text-center bg-slate-200 p-3 m-3 rounded-xl'>
                    <div className='text-center flex justify-center text-xl'>
                    Pickup Site
                    <button className='mx-4' onClick={(e)=>{setIsPickupSiteCreationVisible(!isPickupSiteCreationVisible)}}>
                    <IoMdClose size={25}/>
                    </button>
                   
                    </div>
                    <PickupSiteCreation/>
                                    </div>
            }
            {
                isTyreSizeCreationVisible&&
                <div className='absolute top-28 left-44 w-3/4 max-sm:w-11/12 max-sm:left-0 max-sm:mr-3 text-center bg-slate-200 p-3 m-3 rounded-xl'>
                    <div className='text-center flex justify-center text-xl'>
                    Pickup Site
                    <button className='mx-4' onClick={(e)=>{setIsTyreSizeCreationVisible(!isTyreSizeCreationVisible)}}>
                    <IoMdClose size={25}/>
                    </button>
                   
                    </div>
                    <TyreSizeCreation/>
                                    </div>
                
            }
        </div>
    </div>
  )
}

export default NameList