import React, {useState} from 'react'
import Navbar from '../components/Navbar.jsx'
import Toggle from 'react-toggle'
import xlsx from "xlsx";
import appwriteClient from '../appwrite.config.js';
import { Databases, ID } from 'appwrite';
import { ToastContainer, toast } from 'react-toastify';


function DataImporting() {
    const [database,setDatabase]=useState("");
    const [collection,setCollection]=useState("");
    const [isDocumentIdProvided,setIsDocumentIdProvided]=useState(false);
    const [data,setData]=useState([]);
    const [totalData,setTotalData]=useState(0)
    var uploadedData=0;
    const databases = new Databases(appwriteClient);
    const [dataFields,setDataFields]=useState([]);
    const [curDataField,setCurDataField]=useState("");
    const [errorId, setErrorId]=useState([]);


    const handleFileChange = (e) =>{
      e.preventDefault();
     
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = xlsx.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = xlsx.utils.sheet_to_json(worksheet);

                setData(json);
                console.log(json.length);
                  console.log("Excel Decoding started")
                  setTotalData(json.length)
    }
    reader.readAsArrayBuffer(e.target.files[0]);
}
    }
    const handleImportSubmit = async() =>{
            if(!database && !collection){
              toast.error("Database and Collection must be provided !!")
            }
            else{
              if(isDocumentIdProvided){
                data.map(async(d)=>{
         
               await   databases.createDocument(
                    database,
                    collection,
                    d.id,
                    d                  
                    
                  ).then(async(response)=>{
                    uploadedData++;
                   console.log(uploadedData)
                  }).catch(async(error)=>{
                    console.log("Error encountered while uploading document with id "+d.id+" error :: "+error);
                    setErrorId([...errorId,d.id])
                    uploadedData++;
                    console.log(uploadedData)
                  })
                  
                  await  setTimeout(function() {
                      console.log("Waiting for "+uploadedData);
                    }, 60000);
                  
                })
              }
              else{
                data.map(async(d)=>{
               
                 await databases.createDocument(
                    database,
                    collection,
                    ID.unique(),
                    d                  
                    
                  ).then(async(response)=>{
                    uploadedData++;
                    if(uploadedData%50==0){
                    await  setTimeout(console.log("waiting"),3000);
                    }
                  }).catch(async(error)=>{
                    console.log("Error encountered while uploading document with id "+d.id+" error :: "+error);
                    setErrorId([...errorId,d.tyreSerialNumber])
                    uploadedData++;
                    if(uploadedData%50==0){
                    await  setTimeout(console.log("waiting"),120000);
                    }
                  })
                })
              }
              
            }
    }
  return (
    <>
   <Navbar page={"Data Import"}/>
   <ToastContainer />
   <div className='flex flex-row flex-wrap rounded-lg m-auto mt-5 justify-center w-5/6  p-2 bg-slate-200'>
        <input className='m-3 p-3 rounded-lg' placeholder={"Enter Database ID"} value={database} onChange={(e)=>{setDatabase(e.target.value)}}></input>
        <input className='m-3 p-3 rounded-lg' placeholder={"Enter Collection ID"} value={collection} onChange={(e)=>{setCollection(e.target.value)}}></input>
        <label className='m-3 p-3 rounded-lg'>Does data have document ID</label>
        <Toggle
        className='m-3 p-3'
  defaultChecked={isDocumentIdProvided}
  onChange={()=>{setIsDocumentIdProvided(!isDocumentIdProvided)}} />

<input className='m-3 p-3' type="file" onChange={handleFileChange}/>

<button className='m-3 p-3 rounded-lg bg-blue-400' onClick={handleImportSubmit}>Import</button>
   </div>
   <div className='flex flex-col rounded-lg m-auto mt-5 justify-center w-5/6  p-2 bg-slate-200'>
    <div className='m-auto flex justify-center text-center text-2xl'>Data Fields</div>
    {dataFields.length>0 &&<div className='text-center m-auto '>
    {
       dataFields.map((field)=>{
            return <div className='border-2 rounded-xl border-green-400 p-2 m-2 '>{field}</div>
       })
    }
    {
      errorId.length>0 && <div className='text-center m-auto '>
        {
           errorId.map((field)=>{
            return <div className='border-2 rounded-xl border-red-400 p-2 m-2 '>{field}</div>
       })
        }
        </div>
    }
    </div>}
    <div className='m-auto'>
      <input className='m-3 p-3 rounded-lg' onChange={(e)=>{setCurDataField(e.target.value)}} value={curDataField}></input>
      <button className='m-3 p-3 rounded-lg bg-blue-400' onClick={()=>{setDataFields([...dataFields,curDataField]);setCurDataField("")}}>Add</button>
    </div>
   </div>
{totalData>0 &&
   <div className='w-4/5 m-auto rounded-lg mt-5 p-5 text-center justify-center bg-green-200'>
    Uploaded {uploadedData} of {totalData}
   </div>
   }
   </>
  )
}

export default DataImporting