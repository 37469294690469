import React, { useEffect } from "react";
import { useState } from "react";
import { Collapse } from "react-collapse";
import appwriteClient from "../appwrite.config";
import { Databases } from "appwrite";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';





const WorkOrderUpdate = (wono) => {



  const [workOrderNumber, setWorkOrderNumber] = useState(wono.wono);
  const [id, setId] = useState("");
  const [fleetName, setFleetName] = useState("");
  const [pickupSite, setPickupSite] = useState("");
  const [driver, setDriver] = useState("");
  const [vehicle, setVehicle] = useState("");
  const [prno, setPrno] = useState("");
  const [status, setStatus] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  const databases = new Databases(appwriteClient);

  useEffect(() => {
    databases
      .getDocument("AppwriteCloudFEApp", "WorkOrder", workOrderNumber)
      .then((response) => {
        setFleetName(response.fleetName);
        setPickupSite(response.pickupSite);
        setVehicle(response.vehicle);
        setDriver(response.driver);
        setPrno(response.prno);
        setStatus(response.status);
        setDate(response.date);
        setTime(response.time);
      })
      .catch((error) => {
        toast.error("An error occurred, Please try again later");
        console.log(error);
      });
  }, []);

  const updateWorkOrder = () => {
    if(workOrderNumber==null || fleetName =="" || date=="" || time==""){
        toast.error("Fields are empty, Please try again !!")
    }
    databases.updateDocument(
      "AppwriteCloudFEApp",
      "WorkOrder",
      workOrderNumber,
      {
        date: date,

        driver: driver,

        fleetName: fleetName,

        pickupSite: pickupSite,

        prno: prno,

        status: status,
        time: time,
        vehicle: vehicle,
        workOrderNumber: workOrderNumber,
      }
    ).then((response)=>{
        toast.success("Updated Successfully");
        window.location.reload();
    }).catch((error)=>{
        toast.error("An error occurred, Please try again later");
        console.log(error)
    })
  };

  return (
    <div className=" w-full bg-slate-200 rounded-lg p-3 my-2 justify-center">
     
                  <ToastContainer />
      <div className="m-auto w-full justify-content-center text-center">
   
        <div className="flex flex-wrap">
            
          <div className=" flex flex-col ">
       
            <div className="">
                
              <label className="m-2 p-2 sm:float-left w-40">
                Work Order No
              </label>
              <input
                value={workOrderNumber}
                disabled={true}
                className="m-2 p-2 sm:float-left bg-slate-300 rounded-lg w-72 max-sm:w-4/5"
              ></input>
            </div>
            <div>
              <label className="m-2 p-2 sm:float-left w-40">Fleet Name</label>
              <input
                value={fleetName}
                onChange={(e) => {
                  setFleetName(e.target.value);
                }}
                className="m-2 p-2 bg-slate-300 sm:float-left rounded-lg w-72 max-sm:w-4/5"
              ></input>
            </div>
            <div>
              <label className="m-2 p-2 sm:float-left w-40">Pickup Site</label>
              <input
                value={pickupSite}
                onChange={(e) => {
                  setPickupSite(e.target.value);
                }}
                className="m-2 p-2 bg-slate-300 sm:float-left rounded-lg w-72 max-sm:w-4/5"
              ></input>
            </div>
            <div>
              <label className="m-2 p-2 sm:float-left w-40">Driver</label>
              <input
                value={driver}
                onChange={(e) => {
                  setDriver(e.target.value);
                }}
                className="m-2 p-2 bg-slate-300 sm:float-left rounded-lg w-72 max-sm:w-4/5"
              ></input>
            </div>

            <div>
              <label className="m-2 p-2 sm:float-left w-40">Vehicle</label>
              <input
                value={vehicle}
                onChange={(e) => {
                  setVehicle(e.target.value);
                }}
                className="m-2 p-2 bg-slate-300 sm:float-left rounded-lg w-72 max-sm:w-4/5"
              ></input>
            </div>
          </div>
          <div className=" flex flex-col">
            <div>
              <label className="m-2 p-2 sm:float-left w-40">
                Pickup Request No
              </label>
              <input
                value={prno}
                onChange={(e) => {
                  setPrno(e.target.value);
                }}
                className="m-2 p-2 bg-slate-300 sm:float-right rounded-lg w-72 max-sm:w-4/5"
              ></input>
            </div>
            <div>
              <label className="m-2 p-2 sm:float-left w-40">Status</label>
              <input
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
                value={status}
                className="m-2 p-2 bg-slate-300 sm:float-right rounded-lg w-72 max-sm:w-4/5"
              ></input>
            </div>
            <div>
              <label className="m-2 p-2 sm:float-left w-40">Date</label>
              <input
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                }}
                className="m-2 p-2 bg-slate-300 sm:float-right rounded-lg w-72 max-sm:w-4/5"
              ></input>
            </div>
            <div>
              <label className="m-2 p-2 sm:float-left w-40">Time</label>
              <input
                value={time}
                onChange={(e) => {
                  setTime(e.target.value);
                }}
                className="m-2 p-2 bg-slate-300 sm:float-right rounded-lg w-72 max-sm:w-4/5"
              ></input>
            </div>

            <div className="max-sm:flex-col flex m-auto">
              <button className="bg-blue-400 p-3 py-2 text-white hover:border-blue-400 hover:border-2 hover:bg-slate-200 hover:m-1 hover:text-black  m-2 rounded-lg" onClick={updateWorkOrder} >Update</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkOrderUpdate;
