import React, { useEffect, useState } from 'react'
import { Databases, Query , ID }  from 'appwrite'
import { ToastContainer, toast } from 'react-toastify'
import appwriteClient from '../appwrite.config'
import 'react-toastify/dist/ReactToastify.css'
import Cookies from 'js-cookie'

function FleetProfileCreation(props) {




  const [fleetName,setFleetName]=useState("");
  const [whatsappNumber,setWhastappNumber]=useState("");
  const [contactNumber,setContactNumber]=useState("");
  const [contactPerson,setContactPerson]=useState([]);
  const [gst,setGst]=useState("");
  const [loadingMaterial,setLoadingMaterial]=useState([]);
  const [officeAddress,setOfficeAddress]=useState("");
  const [pndl,setPndl]=useState([]);
  const [retreader,setRetreader]=useState([]);
  const [vehicles,setVehicles]=useState([]);
  const [noOfVehicle,setNoOfVehicle]=useState("");
  const [route,setRoute]=useState([]);
  const [email,setEmail]=useState([]);


  const databases = new Databases(appwriteClient);

  const handleFleetProfileCreation=()=>{
    if(!fleetName || !whatsappNumber || !contactNumber){
      toast.error("Some fields are empty, Please try again !!");
    }
    else if(props.isUpdation){
      databases.updateDocument(
        'AppwriteCloudFEApp',
        '65ea96670e2c9740a984',
        props.id,
        {
          fleetname:fleetName,
          contactNumber:contactNumber,
          whatsappNumber:whatsappNumber,
          email:email,
          contactPerson:contactPerson,
          gst:gst,
          loadingMaterial:loadingMaterial,
          officeAddress:officeAddress,
          pndLocation:pndl,
          retreader:retreader,
          route:route,
          vehicle:vehicles,
          noOfVehicle:noOfVehicle,
          

        }
      ).then((response)=>{
        toast.success("Fleet Profile updated sucessfully");
       window.location.reload();
      }).catch((error)=>{
        toast.error("Error saving data, Please try again later !!");
        console.log(error)
      })
    }
    else{
      databases.createDocument(
        'AppwriteCloudFEApp',
        '65ea96670e2c9740a984',
        ID.unique(),
        {
          fleetname:fleetName,
          contactNumber:contactNumber,
          whatsappNumber:whatsappNumber,
          email:email,
          contactPerson:contactPerson,
          gst:gst,
          loadingMaterial:loadingMaterial,
          officeAddress:officeAddress,
          pndLocation:pndl,
          retreader:retreader,
          route:route,
          vehicle:vehicles,
          noOfVehicle:noOfVehicle,
          createdAt:new Date().toLocaleString(),
          createdBy:Cookies.get("userName")

        }
      ).then((response)=>{
        toast.success("Fleet Profile created sucessfully");
        window.location.reload();
      }).catch((error)=>{
        toast.error("Error saving data, Please try again later !!");
        console.log(error)
      })
    }
  }

useEffect(()=>{
  if(props.isUpdation){

      databases.getDocument(
        'AppwriteCloudFEApp',
        '65ea96670e2c9740a984',
        props.id
      ).then((response)=>{
        setFleetName(response.fleetname);
          setContactNumber(response.contactNumber);
          setWhastappNumber(response.whatsappNumber);
          setEmail(response.email);
          setContactPerson(response.contactPerson);
          setGst(response.gst);
          setLoadingMaterial(response.loadingMaterial);
          setOfficeAddress(response.officeAddress);
          setPndl(response.pndl);
          setRetreader(response.retreader);
          setRoute(response.route);
          setVehicles(response.vehicles);
          setNoOfVehicle(response.noOfVehicle);
               
        }
        ).catch((error)=>{
toast.error("Error occurred while fetching data, Please try again");
console.log(error);
      })
  }
},[])

  return (
    <div className=''>
        <ToastContainer/>
        <div className='flex flex-wrap justify-between max-sm:justify-center'>
            <div className=' flex flex-col w-1/2 max-sm:w-full'>
                <div className='p-2 max-sm:w-full max-sm:flex  max-sm:flex-col'>
                    <label className='m-2 sm:float-left'>Fleet Name</label>
                    <input onChange={(e)=>{setFleetName(e.target.value)}} value={fleetName} className='m-2 p-2 sm:float-right sm:w-72 rounded-lg'></input>
                </div>
                <div className='p-2 max-sm:w-full max-sm:flex max-sm:flex-col'>
                    <label className='m-2 sm:float-left'>Whatsapp Number</label>
                    <input onChange={(e)=>{setWhastappNumber(e.target.value.trim()); setContactNumber(e.target.value.trim())}} value={whatsappNumber} className='m-2 sm:float-right sm:w-72 p-2 rounded-lg'></input>
                </div>
                <div className='p-2 max-sm:w-full max-sm:flex max-sm:flex-col'>
                    <label className='m-2 sm:float-left'>Email</label>
                    <input onChange={(e)=>{setEmail(e.target.value.split(","))}} value={email} className='m-2 sm:float-right sm:w-72 p-2 rounded-lg'></input>
                </div>
                <div className='p-2 max-sm:w-full max-sm:flex max-sm:flex-col'>
                    <label className='m-2 sm:float-left'>Contact Number</label>
                    <input value={contactNumber} onChange={(e)=>{setContactNumber(e.target.value.trim())}} className='m-2 sm:float-right sm:w-72 p-2 rounded-lg'></input>
                </div>
                <div className='p-2 max-sm:w-full max-sm:flex max-sm:flex-col'>
                    <label className='m-2 sm:float-left'>Contact Person</label>
                    <input onChange={(e)=>{setContactPerson(e.target.value.split(","))}} value={contactPerson} className='m-2 sm:float-right sm:w-72 p-2 rounded-lg'></input>
                </div>
                <div className='p-2 max-sm:w-full max-sm:flex max-sm:flex-col'>
                    <label className='m-2 sm:float-left'>GST</label>
                    <input onChange={(e)=>{setGst(e.target.value.toUpperCase())}} value={gst} className='m-2 sm:float-right sm:w-72 p-2 rounded-lg'></input>
                </div>
                <div className='p-2 max-sm:w-full max-sm:flex max-sm:flex-col'>
                    <label className='m-2 sm:float-left'>Loading Material</label>
                    <input onChange={(e)=>{setLoadingMaterial(e.target.value.split(","))}} value={loadingMaterial} className='m-2 sm:float-right sm:w-72 p-2 rounded-lg'></input>
                </div>
            </div>
            <div className='flex flex-col w-1/2 max-sm:w-full'>
            <div className='p-2 max-sm:w-full max-sm:flex  max-sm:flex-col'>
                    <label className='m-2 align-middle sm:float-left'>Office Address</label>
                    <input onChange={(e)=>{setOfficeAddress(e.target.value)}} value={officeAddress} className='m-2 p-2 sm:float-right sm:w-72 rounded-lg'></input>
                </div>
                <div className='p-2 max-sm:w-full max-sm:flex  max-sm:flex-col'>
                    <label className='m-2 align-middle sm:float-left'>Pickup & Drop Location</label>
                    <input onChange={(e)=>{setPndl(e.target.value.split(","))}} value={pndl} className='m-2 p-2 sm:float-right sm:w-72 rounded-lg'></input>
                </div>
                <div className='p-2 max-sm:w-full max-sm:flex  max-sm:flex-col'>
                    <label className='m-2 align-middle sm:float-left'>Retreader</label>
                    <input onChange={(e)=>{setRetreader(e.target.value.split(","))}} value={retreader} className='m-2 p-2 sm:float-right sm:w-72 rounded-lg'></input>
                </div>
                <div className='p-2 max-sm:w-full max-sm:flex  max-sm:flex-col'>
                    <label className='m-2 align-middle sm:float-left'>Vehicles</label>
                    <input onChange={(e)=>{setVehicles(e.target.value.split(","))}} value={vehicles} className='m-2 p-2 sm:float-right sm:w-72 rounded-lg'></input>
                </div>
                <div className='p-2 max-sm:w-full max-sm:flex  max-sm:flex-col'>
                    <label className='m-2 align-middle sm:float-left'>No. Of Vehicles</label>
                    <input onChange={(e)=>{setNoOfVehicle(e.target.value)}} value={noOfVehicle} className='m-2 p-2 sm:float-right sm:w-72 rounded-lg'></input>
                </div>
                <div className='p-2 max-sm:w-full max-sm:flex  max-sm:flex-col'>
                    <label className='m-2 align-middle sm:float-left'>Route</label>
                    <input onChange={(e)=>{setRoute(e.target.value.split(","))}} value={route} className='m-2 p-2 sm:float-right sm:w-72 rounded-lg'></input>

                </div>
            </div>
        </div>
        <div>
          <button onClick={handleFleetProfileCreation} className='m-2 p-2 bg-blue-400 rounded-lg px-10 text-white hover:bg-slate-200 hover:text-black border-2 border-blue-400'>Submit</button>
        </div>
    </div>
  )
}

export default FleetProfileCreation