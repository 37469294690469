import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import { ToastContainer, toast } from 'react-toastify'
import { Databases, Query } from 'appwrite';
import appwriteClient from '../appwrite.config';
import Select from 'react-select'
import MonthlyRunningForm from '../components/MonthlyRunningForm';
import { IoMdClose } from 'react-icons/io';

const MonthlyRun = () => {


  const [columns,setColumns]=useState([
    {
      title:"Action",
      key:"action"
    },
      
      {
          title:"Fleet Name",
          key:"fleetname"
      },
      {
          title:"Vehicle Profile",
          key:"vehicleRegNo"
      },
      {
          title:"Month",
          key:"month"
      },
      {
        title:"KMs Run",
        key:"kmsRun"
      },
      {
        title:"Remarks",
        key:"remarks"
      },
      {
        title:"User",
        key:"createdBy"
      },
      {
        title:"Timestamp",
        key:"createdOn"
      }
    ]);


    const [fleetNameDropdown,setFleetNameDropdown] = useState([]);
    const [vehicleRegNoDropdown,setVehicleRegNoDropdown] = useState([]);
    const [selectedFleetName,setSelectedFleetName] = useState([]);
    const [selectedVehicleRegNo,setSelectedVehicleRegNo] = useState([]);
    const [monthlyRunData,setMonthlyRunData] = useState([])
    const databases = new Databases(appwriteClient);
    const [isMonthlyRunFormVisible,setIsMonthlyRunFormVisible] = useState(false);
    const [isUpdation,setIsUpdation] = useState(false);
    const [updationId,setUpdationId] = useState(); 
    const [loading,setLoading] = useState(false);
    const [itemsPerPage,setItemsPerPage]=useState(10);
    const [firstLoad,setFirstLoad]=useState(true);
    const [totalPages,setTotalPages]=useState();
    const [lastId,setLastId]=useState("");
    const [currentPage, setCurrentPage] = useState(1);


    const handleChangePage = (page) => {
      if (page >= 1 && page <= totalPages) {
        setCurrentPage(page);
      
      }
    };

    useEffect(()=>{
        databases.listDocuments(
            'AppwriteCloudFEApp',
            '65ea96670e2c9740a984',
            [
                Query.limit(500),
                
            ]
        ).then((response)=>{
            const fleetNameDropdownarr=[];
            response.documents.map((data)=>{
                fleetNameDropdownarr.push(
                {value:data.fleetname,label:data.fleetname}
              ) 
            })
            setFleetNameDropdown(fleetNameDropdownarr);
        }).catch((error)=>{
            toast.error("An error occurred while getting Fleetname dropdown, Please try again later !!");
            console.log(error);
        })
    },[])

    
    useEffect(()=>{
        if(selectedFleetName.length>0){
            
         databases.listDocuments(
             'AppwriteCloudFEApp',
             'VehicleProfile',
             [
                 Query.equal("fleetname",selectedFleetName),
                 Query.limit(1000)
                 
             ]
         ).then((response)=>{
             const vehicleDropdownArr=[]
            
             response.documents.map((data)=>{
                
               vehicleDropdownArr.push(
                 {value:data.$id,label:data.vehicleRegistrationNumber}
               ) 
             })
     
             setVehicleRegNoDropdown(vehicleDropdownArr)
         }).catch((error)=>{
             toast.error("An error occurred while getting Vehicle dropdown, Please try again later !!");
             console.log(error);
         })
        }
     },[selectedFleetName])


     const handleSearch = async() =>{         
      if(selectedFleetName.length<1 || selectedVehicleRegNo.length<1 ){
            toast.error("Please select Fleet Name and Vehicle Registration Number to Proceed !!")
       }
      else{
        setLoading(true);
       await databases.listDocuments('AppwriteCloudFEApp',
        'MonthlyRunningData',[Query.equal("vehicleProfile",selectedVehicleRegNo),  Query.limit(itemsPerPage),
          (currentPage>1)?Query.cursorAfter(lastId):Query.offset(0),Query.orderDesc("month")]
       ).then((response)=>{
        setFirstLoad(false);
        setLastId(response.documents[response.documents.length - 1].$id);
        if(currentPage===1){setTotalPages(Math.ceil(response.total / itemsPerPage))};
  
  if(currentPage===1){
  setMonthlyRunData(response.documents)
  console.log(response.documents)
  }
  else{
    setMonthlyRunData([...monthlyRunData,...response.documents])
  }
         setLoading(false);
       }).catch((err)=>{
        console.log(err);
        toast.error("Error occured while getting monthly running data, Please try again later !!");
        setLoading(false);
       })
      }
     }
     useEffect(()=>{
      if(!firstLoad){
handleSearch()
      }
     },[itemsPerPage, currentPage]);

     

  const [filterText, setFilterText] = useState({});

  const handleFilterChange = (key, value) => {

    setFilterText({ ...filterText, [key]: value });
    
    
  };

  const applyFilters = (row) => {
    
    return Object.entries(filterText).every(([key, values]) => {
      const cellValue = row[key]?row[key].toString().toLowerCase():"";
      
      return values.some(filterValue => cellValue.includes(filterValue?filterValue.toLowerCase():""));
    }
)};

  const filteredData = monthlyRunData.filter(row => applyFilters(row));

  const renderTableHeaders = () => {

    return columns.map((col, index) => (
      <th key={index}>
        <div className='p-1 flex flex-col'>
        {col.title}{''}
        {(col.key!="action")?
        <input
          type="text"
          className='p-2 rounded-md text-black'
          value={filterText[col.key] || ''}
          onChange={(e) => handleFilterChange(col.key, e.target.value.split(','))}
          placeholder={`Filter ${col.title}`}
        />:<></>}
        </div>
      </th>
    ));
  };

  const renderTableData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    if(filteredData.length>=1){
    return filteredData.slice(startIndex, endIndex).map((row, index) => {

      return (
        <>
        <tr key={row.$id} className='text-center border-t-2 bg-white text-black'>
          <td>{
            <div className='flex px-3'>
            
            
           
            {/* <button onClick={(e)=>{setIsFleetNameCardVisible(!isFleetNameCardVisible);setIsUpdation(true);setUpdationId(row.$id)}}>
                        <CiEdit className='mx-2 hover:cursor-pointer' color='blue' size={25}/>
                      </button>
                      <button onClick={()=>deleteFleetProfile(row.$id)}>
            <MdDeleteOutline className='mx-2 hover:cursor-pointer' color='orange' size={25} />
            </button> */}
          </div>
          }</td>
          {columns.map((col, colIndex) => (
            (col.key=="vehicleRegNo")?<td>{row.vehicleProfile.vehicleRegistrationNumber}</td>:(col.key=="fleetname")?<td>{row.fleetProfile?.fleetname}</td>:(col.key!=="action")?(col.key=="email")?<td key={colIndex}>{row[col.key]?.map((data)=>{return (data+" , ")})}</td>:<td key={colIndex}>{
              row[col.key]}</td>:""
          ))}
        </tr>
</>
)
})
}
}

  return (
    <>
    <Navbar page={"Work Order"}/>
    {
                isMonthlyRunFormVisible&&
                <div className='fixed  top-28 left-44 w-3/4 max-sm:w-11/12 max-sm:left-0 max-sm:mr-3 text-center bg-slate-200 p-3 m-3 rounded-xl'>
                    <div className='text-center flex justify-center text-xl'>
                    Fleet Profile 
                    <button className='mx-4' onClick={(e)=>{setIsMonthlyRunFormVisible(!isMonthlyRunFormVisible)}}>
                    <IoMdClose size={25}/>
                    </button>
                   
                    </div>
                    <MonthlyRunningForm isUpdation={isUpdation} id={updationId}/>
                </div>
            }
      <ToastContainer/>
      <div className='flex w-full justify-end'>
                <button onClick={()=>{setIsMonthlyRunFormVisible(!isMonthlyRunFormVisible)}} className=' p-2 text-nowrap rounded-lg text-white m-2 bg-blue-400 hover:bg-slate-200 hover:border-2 hover:border-blue-400 hover:text-black'>Add Monthly Running</button>
            </div>
      {!isMonthlyRunFormVisible&&<div className='bg-gray-200 w-4/5 m-auto mt-5 justify-center p-5 rounded-2xl'>
            <div className='flex sm:flex-row sm:flex-wrap justify-center max-sm:flex-col'>
               
                <div className='sm:w-2/5 max-sm:w-full sm:m-1 p-3 text-center'>
                <label>Fleet Name</label>
                <Select
  
    isMulti
    name="fleetname"
    options={fleetNameDropdown}
    className="basic-multi-select"
    classNamePrefix="select"
    onChange={(e)=>{const selectedFleetNameArr=[];e.map((data)=>{selectedFleetNameArr.push(data.value)});setSelectedFleetName(selectedFleetNameArr)}}
  />
                </div>
                <div className='sm:w-2/5 max-sm:w-full sm:m-1 p-3 text-center'>
                <label>Vehicle Reg. No.</label>
                <Select
  
    isMulti
    name="vehicleRegNo"
    options={vehicleRegNoDropdown}
    className="basic-multi-select"
    classNamePrefix="select"
    onChange={(e)=>{const selectedVehicleArr=[];e.map((data)=>{selectedVehicleArr.push(data.value)});setSelectedVehicleRegNo(selectedVehicleArr)}}
  />
                </div>
                <button onClick={()=>{handleSearch()}} disabled={loading} className='m-auto h-full justify-items-center bg-blue-400 text-white p-2 border-2 border-blue-400 hover:bg-slate-200 hover:text-black px-4 hover: rounded-lg'>Get Data</button>
                </div>
                
                </div>}
      {!loading&&monthlyRunData.length>0&&
        <div >
            {!loading&&
     <div className='  overflow-x-scroll p-6 w-full'>
     <table className="table  w-full rounded-md   bg-blue-400 text-white">
       <thead>
         <tr>{renderTableHeaders()}</tr>
       </thead>
       <tbody  >{renderTableData()}</tbody>
     </table>
     </div>
     
}
<div className='m-5 flex justify-between max-sm:m-2'>
      <div>
        <button className='bg-blue-400 text-white p-2 rounded-md w-24 m-2 max-sm:m-1' onClick={() => handleChangePage(currentPage - 1)} disabled={(currentPage === 1) || loading}>
          Previous
        </button>
        </div>
        <div className='m-2 mt-4 max-sm:m-1 max-sm:mt-2'>
        <span className='m-2'>Page {currentPage} of {totalPages}</span>
        <select name="pages" id="pages" onChange={(e)=>{setItemsPerPage(e.target.value)}}>
  <option value="10">10</option>
  <option value="30">30</option>
  <option value="50">50</option>
  <option value="100">100</option>
</select>
        </div>
        <div>
        <button className='bg-blue-400 text-white p-2 rounded-md w-24 m-2 max-sm:m-1' onClick={() => handleChangePage(currentPage + 1)} disabled={(currentPage === totalPages) || loading }>
          Next
        </button>
        </div>
      </div>
    </div>
      }
    </>
  )
}

export default MonthlyRun
