import React, { useState,useEffect } from 'react'
import { Databases, Query , ID }  from 'appwrite'
import { ToastContainer, toast } from 'react-toastify'
import appwriteClient from '../appwrite.config'
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css'
import Cookies from 'js-cookie'

const TyreSizeCreation = () => {

    const navigate = useNavigate();
    useEffect(()=>{
  
        if(Cookies.get("userId")==undefined){
          navigate('/login', { replace: true })
        }
      },[])


    const [displayName,setDisplayName]=useState("");
    const [	width,setWidth]=useState();
    const [	circumference,setCircumference]=useState();
  
    
  const databases = new Databases(appwriteClient);


  const handleTyreSizeCreation=()=>{
    if(!displayName){
      toast.error("Some fields are empty, Please try again !!");
    }
    else{
      databases.createDocument(
        'AppwriteCloudFEApp',
        'TyreSizeDropDown',
        ID.unique(),
        {
      displayName:displayName,
      width:parseInt(width),
      circumference:parseInt(circumference),
          createdOn:new Date().toLocaleString(),
          createdBy:Cookies.get("userName")

        }
      ).then((response)=>{

        toast.success("Tyre size created sucessfully");
        window.location.reload();
      }).catch((error)=>{
        toast.error("Error saving data, Please try again later !!");
        console.log(error)
      })
    }
  }


  return (
     <div className=''>
    <ToastContainer/>
    <div className='flex flex-wrap justify-between max-sm:justify-center'>
        <div className=' flex flex-col w-1/2 max-sm:w-full'>
            <div className='p-2 max-sm:w-full max-sm:flex  max-sm:flex-col'>
                <label className='m-2 sm:float-left'>Tyre Size</label>
                <input onChange={(e)=>{setDisplayName(e.target.value)}} value={displayName} className='m-2 p-2 sm:float-right sm:w-72 rounded-lg'></input>
            </div>
            <div className='p-2 max-sm:w-full max-sm:flex max-sm:flex-col'>
                <label className='m-2 sm:float-left'>Width</label>
                <input type="number" onChange={(e)=>{setWidth(e.target.value)}} value={width} className='m-2 sm:float-right sm:w-72 p-2 rounded-lg'></input>
            </div>
           
           
            
            
        </div>
        <div className='flex flex-col w-1/2 max-sm:w-full'>
        <div className='p-2 max-sm:w-full max-sm:flex  max-sm:flex-col'>
                <label className='m-2 align-middle sm:float-left'>Circumference</label>
                <input type="number" onChange={(e)=>{setCircumference(e.target.value)}} value={circumference  }  className='m-2 p-2 sm:float-right sm:w-72 rounded-lg'></input>
            </div>
           
            
         
            
        </div>
    </div>
    <div>
      <button onClick={handleTyreSizeCreation} className='m-2 p-2 bg-blue-400 rounded-lg px-10 text-white hover:bg-slate-200 hover:text-black border-2 border-blue-400'>Submit</button>
    </div>
</div>
  )
}

export default TyreSizeCreation