import { Databases, Query }  from 'appwrite';
import React, { useMemo, useState,useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import appwriteClient from '../appwrite.config';
import { CiCircleChevDown } from "react-icons/ci";
import Collapsible from 'react-collapsible';
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import { Link, useNavigate } from 'react-router-dom';
import { IoMdClose } from "react-icons/io";
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import PickupRequestUpdationCard from '../components/PickupRequestUpdationCard';
import PickupRequestTable from '../components/PickupRequestTable';
import Navbar from '../components/Navbar';

const PickupRequest = () => {

    const navigate = useNavigate();
    
  
        
     const [loading,setLoading]=useState();
     const [activeTab,setActiveTab]=useState("PendingPickupRequest");




  return (
    <>
    <Navbar page={"Pickup Request"}/>
    <div className='p-3'>
    <ToastContainer/>
    
    <div className='bg-slate-200 flex m-3 rounded-lg p-1 h-16 '>
            <div className='flex max-sm:overflow-x-scroll w-3/4'>
<button
onClick={(e)=>{setActiveTab("PendingPickupRequest")}}
className={`${activeTab==="PendingPickupRequest"?"bg-blue-400":""} ${activeTab==="PendingPickupRequest"?"text-white":""} p-2 text-nowrap rounded-lg hover:text-white m-2 hover:bg-blue-400`}>
   Pending PR
</button>
<button 
onClick={(e)=>{setActiveTab("OngoingPickupRequest")}}
className={`${activeTab==="OngoingPickupRequest"?"bg-blue-400":""} ${activeTab==="OngoingPickupRequest"?"text-white":""} p-2 text-nowrap rounded-lg hover:text-white m-2 hover:bg-blue-400`}>
 Ongoing PR
</button>
<button
onClick={(e)=>{setActiveTab("CompletedPickupRequest")}}
className={`${activeTab==="CompletedPickupRequest"?"bg-blue-400":""} ${activeTab==="CompletedPickupRequest"?"text-white":""} p-2 text-nowrap rounded-lg hover:text-white m-2 hover:bg-blue-400`}>
    Completed PR
</button>

            </div>
           
        </div>
        <div>
           {activeTab==="PendingPickupRequest"?<PickupRequestTable status={["PickupRequested"]}/>:""}
            {activeTab==="OngoingPickupRequest"?<PickupRequestTable status={["TripAlloted","TripStarted"]}/>:""}
            {activeTab==="CompletedPickupRequest"?<PickupRequestTable status={["TripEnded","Cancelled"]}/>:""}
           </div>
  </div>
  </>
  )
}

export default PickupRequest