import { Databases, Query } from 'appwrite';
import React, { useEffect, useState } from 'react'
import appwriteClient from '../appwrite.config';
import { confirmAlert } from 'react-confirm-alert';
import { ToastContainer, toast } from 'react-toastify';
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";

function VehicleProfileTable() {
  const [columns,setColumns]=useState([
    {
      title:"Action",
      key:"action"
    },
      
      {
          title:"Vehicle Make",
          key:"vehicleMake"
      },
      {
          title:"Model",
          key:"vehicleModel"
      },
      {
          title:"Material Carried",
          key:"loadingMaterial"
      },
      {
        title:"Vehicle Type",
        key:"vehicleType"
      },
      {
          title:"Fleet Name",
          key:"fleetname"
      },
      {
          title:"Vehicle Reg. No.",
          key:"vehicleRegistrationNumber"
      },
      {
          title:"Driver",
          key:"driverName"
      },
      {
          title:"Driver No.",
          key:"driverNumber"
      },
      {
        title:"Created By",
        key:"createdBy"
    },
    {
      title:"Created On",
      key:"createdOn"
  },
  {
    title:"Status",
    key:"status"
},
{
  title:"Avg Run",
  key:"avgRun"
},

      ]);

      const [loading,setLoading]=useState(false);
      const [vehicleProfileData,setVehicleProfileData]=useState([]);
      const [isVehicleProfileCardVisible,setVehicleProfileCardVisible]=useState(false);
      const databases = new Databases(appwriteClient);
      const [itemsPerPage,setItemsPerPage]=useState(10);
      const [totalPages,setTotalPages]=useState();
      const [lastId,setLastId]=useState("");
      const [currentPage, setCurrentPage] = useState(1);
      const [isUpdation,setIsUpdation]=useState(false);
      const [updationId,setUpdationId]=useState("");

      const handleChangePage = (page) => {
        if (page >= 1 && page <= totalPages) {
          setCurrentPage(page);
        
        }
      };
      const deleteFleetProfile=(id)=>{
        if(id){
          confirmAlert({
            title: 'Confirm to Delete ',
            message: 'Are you sure to delete this Vehicle Profile ?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                  databases.deleteDocument('AppwriteCloudFEApp',
                  'VehicleProfile',
                  id
                  ).then((respose)=>{
                                 
                    toast.success("Deleted Successfully");
                    window.location.reload();
                  }).catch((error)=>{
                    toast.error("An error occurred, Please try again later");
                  })
                }
              },
              {
                label: 'No',
                onClick: () => {}
              }
            ]
          });
        }
      }
      
      useEffect(()=>{
        setLoading(true)
        databases.listDocuments(
          'AppwriteCloudFEApp',
          'VehicleProfile',
          [
            Query.orderAsc('fleetname'), 
          
            Query.limit(itemsPerPage),
            (currentPage>1)?Query.cursorAfter(lastId):Query.offset(0),
          ]
        ).then((response)=>{
          setLastId(response.documents[response.documents.length - 1].$id);
          if(currentPage===1){setTotalPages(Math.ceil(response.total / itemsPerPage))};
    
    if(currentPage===1){
    setVehicleProfileData(response.documents)
    }
    else{
         setVehicleProfileData([...vehicleProfileData,...response.documents])
    }
    
         setLoading(false)
        }).catch((error)=>{
          toast.error("Error fetching data, Please try again later !!");
          console.log(error);
          setLoading(false)
        })
      },[itemsPerPage, currentPage])
    
    
      const [filterText, setFilterText] = useState({});

      const handleFilterChange = (key, value) => {
    
        setFilterText({ ...filterText, [key]: value });
        
        
      };
    
      const applyFilters = (row) => {
        
        return Object.entries(filterText).every(([key, values]) => {
          const cellValue = row[key]?row[key].toString().toLowerCase():"";
          
          return values.some(filterValue => cellValue.includes(filterValue?filterValue.toLowerCase():""));
        }
    )};
    
      const filteredData = vehicleProfileData.filter(row => applyFilters(row));
    


      const renderTableHeaders = () => {

        return columns.map((col, index) => (
          <th key={index}>
            <div className='p-1 flex flex-col'>
            {col.title}{''}
            {(col.key!="action")?
            <input
              type="text"
              className='p-2 rounded-md text-black'
              value={filterText[col.key] || ''}
              onChange={(e) => handleFilterChange(col.key, e.target.value.split(','))}
              placeholder={`Filter ${col.title}`}
            />:<></>}
            </div>
          </th>
        ));
      };


      const renderTableData = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        if(filteredData.length>=1){
        return filteredData.slice(startIndex, endIndex).map((row, index) => {
    
          return (
            <>
            <tr key={row.$id} className='text-center border-t-2 bg-white text-black'>
              <td>{
                <div className='flex px-3'>
                
                
               
                <button onClick={(e)=>{setVehicleProfileCardVisible(!isVehicleProfileCardVisible);setIsUpdation(true);setUpdationId(row.$id)}}>
                            <CiEdit className='mx-2 hover:cursor-pointer' color='blue' size={25}/>
                          </button>
                          <button onClick={()=>deleteFleetProfile(row.$id)}>
                <MdDeleteOutline className='mx-2 hover:cursor-pointer' color='orange' size={25} />
                </button>
              </div>
              }</td>
              {columns.map((col, colIndex) => (
                (col.key!=="action")?(col.key=="email")?<td key={colIndex}>{row[col.key]?.map((data)=>{return (data+" , ")})}</td>:<td key={colIndex}>{
                  row[col.key]}</td>:""
              ))}
            </tr>
    </>
    )
    })
    }
    }




  return (
    <div className='p-3'>
    <ToastContainer/>
    <div className='overflow-x-scroll '>
    {/* {
              isFleetNameCardVisible&&
              <div className='absolute  top-28 left-44 w-3/4 max-sm:w-11/12 max-sm:left-0 max-sm:mr-3 text-center bg-slate-200 p-3 m-3 rounded-xl'>
                  <div className='text-center flex justify-center text-xl'>
                  Fleet Profile 
                  <button className='mx-4' onClick={(e)=>{setIsFleetNameCardVisible(!isFleetNameCardVisible)}}>
                  <IoMdClose size={25}/>
                  </button>
                 
                  </div>
                  <FleetProfileCreation isUpdation={isUpdation} id={updationId}/>
              </div>
          } */}
    {!loading&&
   
    <table className="table  w-full rounded-md   bg-blue-400 text-white">
      <thead>
        <tr>{renderTableHeaders()}</tr>
      </thead>
      <tbody  >{renderTableData()}</tbody>
    </table>
    
}
</div>
    <div className='m-5 flex justify-between max-sm:m-2'>
    <div>
      <button className='bg-blue-400 text-white p-2 rounded-md w-24 m-2 max-sm:m-1' onClick={() => handleChangePage(currentPage - 1)} disabled={(currentPage === 1) || loading}>
        Previous
      </button>
      </div>
      <div className='m-2 mt-4 max-sm:m-1 max-sm:mt-2'>
      <span className='m-2'>Page {currentPage} of {totalPages}</span>
      <select name="pages" id="pages" onChange={(e)=>{setItemsPerPage(e.target.value)}}>
<option value="10">10</option>
<option value="30">30</option>
<option value="50">50</option>
<option value="100">100</option>
<option value="100">500</option>
</select>
      </div>
      <div>
      <button className='bg-blue-400 text-white p-2 rounded-md w-24 m-2 max-sm:m-1' onClick={() => handleChangePage(currentPage + 1)} disabled={(currentPage === totalPages) || loading }>
        Next
      </button>
      </div>
    </div>
  </div>
  )
}

export default VehicleProfileTable