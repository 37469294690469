import { Databases, Query }  from 'appwrite';
import React, { useMemo, useState,useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import appwriteClient from '../appwrite.config';
import { CiCircleChevDown } from "react-icons/ci";
import Collapsible from 'react-collapsible';
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import { Link, useNavigate } from 'react-router-dom';
import { IoMdClose } from "react-icons/io";
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import PickupRequestUpdationCard from '../components/PickupRequestUpdationCard';
const PickupRequestTable = (props) => {
    const navigate = useNavigate();
    useEffect(()=>{
  
        if(Cookies.get("userId")==undefined){
          navigate('/login', { replace: true })
        }
      },[])
      const [columns,setColumns]=useState([
        {
          title:"Action",
          key:"action"
        },
          {
              title:"PR. No.",
              key:"pickupRequestNumber",
              size:"4"
          },
          {
              title:"Fleet Name",
              key:"fleetName",
              size:"20"
          },
          {
              title:"Pickup Site",
              key:"pickupSite",
              size:"10"
          },
               {
                title:"Pickup Date",
              key:"pickupDate",
              size:"5"
               },
               {
                title:"Pickup Time",
              key:"pickupTime",
              size:"5"
               },

          {
              title:"Tyre Sizes",
              key:"tyreSizes",
              size:"5"
          },
          {
              title:"Tyre Quantity",
              key:"tyreQuantity",
              size:"8"
          },
          {
            title:"Status",
            key:"status",
            size:"5"
        },
          {
            title:"User",
            key:"createdBy",
            size:"5"
        },
        {
          title:"Time",
          key:"createdOn",
          size:"5"
      }
          ]);




          const [loading,setLoading]=useState(false);
          const [pickupRequestData,setPickupRequestData]=useState([]);
          const databases = new Databases(appwriteClient);
          const [itemsPerPage,setItemsPerPage]=useState(10);
          const [totalPages,setTotalPages]=useState();
          const [lastId,setLastId]=useState("");
          const [currentPage, setCurrentPage] = useState(1);
          const [isPickupRequestCard,setIsPickupRequestCard]=useState(false);
          const [isUpdation,setIsUpdation]=useState(false);
          const [updateId,setUpdateId]=useState("");


          const handleChangePage = (page) => {
            if (page >= 1 && page <= totalPages) {
              setCurrentPage(page);
            
            }
          };

          const deletePickupRequest=(id)=>{
            if(id){
              confirmAlert({
                title: 'Confirm to Delete ',
                message: 'Are you sure to delete this Pickup Request ?',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => {
                      databases.deleteDocument('AppwriteCloudFEApp',
                      'PickupRequest',
                      id
                      ).then((respose)=>{
                                     
                        toast.success("Deleted Successfully");
                        window.location.reload();
                      }).catch((error)=>{
                        toast.error("An error occurred, Please try again later");
                      })
                    }
                  },
                  {
                    label: 'No',
                    onClick: () => {}
                  }
                ]
              });
            }
          }
          
          useEffect(()=>{
            setLoading(true)
            databases.listDocuments(
              'AppwriteCloudFEApp',
              'PickupRequest',
              [
                Query.equal("status",props.status),
                Query.orderDesc('pickupRequestNumber'), 
              
                Query.limit(itemsPerPage),
                (currentPage>1)?Query.cursorAfter(lastId):Query.offset(0),
              ]
            ).then((response)=>{
              setLastId(response.documents[response.documents.length - 1].$id);
              if(currentPage===1){setTotalPages(Math.ceil(response.total / itemsPerPage))};
              if(currentPage===1){
                setPickupRequestData(response.documents)
                }
                else{
                     setPickupRequestData([...pickupRequestData,...response.documents])
                }
             setLoading(false)
            }).catch((error)=>{
              toast.error("Error fetching data, Please try again later !!");
              console.log(error);
              setLoading(false)
            })
          },[itemsPerPage, currentPage])



          const [filterText, setFilterText] = useState({});
        
          const handleFilterChange = (key, value) => {
              setFilterText({ ...filterText, [key]: value });
            };


            const applyFilters = (row) => {
    
              return Object.entries(filterText).every(([key, values]) => {
                const cellValue = row[key]?row[key].toString().toLowerCase():"";
                
                return values.some(filterValue => cellValue.includes(filterValue?filterValue.toLowerCase():""));
              }
          )};

          const filteredData = pickupRequestData.filter(row => applyFilters(row));


          const renderTableHeaders = () => {

            return columns.map((col,index) => (
              <th  key={index}>
                <div className='p-1 flex flex-col'>
                {col.title}{''}
                {(col.key!=="action")?
                <input
                size={col.size}
                  type="text"
                  className='p-2 rounded-md text-black'
                  value={filterText[col.key] || ''}
                  onChange={(e) => handleFilterChange(col.key, e.target.value.split(','))}
                  placeholder={`Filter ${col.title}`}
                />:<></>}
                </div>
              </th>
            ));
          };

          const renderTableData = () => {
            const startIndex = (currentPage - 1) * itemsPerPage;
            const endIndex = startIndex + itemsPerPage;
            if(filteredData.length>=1){
            return filteredData.slice(startIndex, endIndex).map((row, index) => {
        
              return (
                <>
                <tr key={row.$id} className='text-center border-t-2 bg-white text-black'>
                  <td>{
                    <div className='flex px-3'>
                      <button onClick={(e)=>{setIsPickupRequestCard(!isPickupRequestCard);setIsUpdation(true);setUpdateId(row.$id)}}>
                        <CiEdit className='mx-2 hover:cursor-pointer' color='blue' size={25}/>
                      </button>
                    <button onClick={()=>deletePickupRequest(row.$id)}> 
                     <MdDeleteOutline className='mx-2 hover:cursor-pointer' color='orange' size={25} /> 
                     </button>
                  </div>
                  }</td>
                  {columns.map((col, colIndex) => (
                    col.key==='tyreSizes'?row[col.key]?<td key={colIndex}>{row[col.key].map((e)=>{return e+" , "})}</td>:"":
                    col.key==="createdOn"?<td>{new Date(row[col.key]).getFullYear()+"/"+new Date(row[col.key]).getMonth()+"/"+new Date(row[col.key]).getDate()+" "+new Date(row[col.key]).getHours()+":"+new Date(row[col.key]).getMinutes()}</td>:
                    col.key==="tyreQuantity"?row[col.key]?<td key={colIndex}>{row[col.key].map((e)=>{return e+" , "})}</td>:"":               
                    (col.key!=="action")?<td className='overflow-hidden' key={colIndex}>{
                      row[col.key]}</td>:""
                  ))}
                </tr>
        </>
        )
        })
        }
        }

  return (
    <div className='p-3'>
    <ToastContainer/>
    <div className='overflow-x-scroll '>
    {!loading&&
    <>
    
    <table className="table  w-full rounded-md   bg-blue-400 text-white">
      <thead>
        <tr>{renderTableHeaders()}</tr>
      </thead>
      <tbody  >{renderTableData()}</tbody>
    </table>
    </>
}
</div>
    <div className='m-5 flex justify-between max-sm:m-2'>
    <div>
      <button className='bg-blue-400 text-white p-2 rounded-md w-24 m-2 max-sm:m-1' onClick={() => handleChangePage(currentPage - 1)} disabled={(currentPage === 1) || loading}>
        Previous
      </button>
      </div>
      <div className='m-2 mt-4 max-sm:m-1 max-sm:mt-2'>
      <span className='m-2'>Page {currentPage} of {totalPages}</span>
      <select name="pages" id="pages" onChange={(e)=>{setItemsPerPage(e.target.value)}}>
<option value="10">10</option>
<option value="30">30</option>
<option value="50">50</option>
<option value="100">100</option>
</select>
      </div>
      <div>
      <button className='bg-blue-400 text-white p-2 rounded-md w-24 m-2 max-sm:m-1' onClick={() => handleChangePage(currentPage + 1)} disabled={(currentPage === totalPages) || loading }>
        Next
      </button>
      </div>
    </div>
    {isPickupRequestCard&&
      <div className='absolute top-28 left-44 w-3/4 max-sm:w-11/12 max-sm:left-0 max-sm:mr-3 text-center bg-slate-200 p-3 m-3 rounded-xl'>
      <div className='text-center flex justify-center text-xl'>
   Pickup Request Update
      <button className='mx-4' onClick={(e)=>{setIsPickupRequestCard(!isPickupRequestCard)}}>
      <IoMdClose size={25}/>
      </button>
      </div>
     {<PickupRequestUpdationCard isUpdation={true} id={updateId}/>} 
      </div>
    }
  </div>
  )
}

export default PickupRequestTable