import React, { useState } from 'react'
import { Databases, Query , ID }  from 'appwrite'
import { ToastContainer, toast } from 'react-toastify'
import appwriteClient from '../appwrite.config'
import 'react-toastify/dist/ReactToastify.css'
import Cookies from 'js-cookie'

function PickupSiteCreation() {
  const [pickupSiteName,setPickupSiteName]=useState("");
  const [	pickupAddress,setPickupAddress]=useState("");
  const [	distance,setDistance]=useState("");


  const databases = new Databases(appwriteClient);

  const handlePickupSiteCreation=()=>{
    if(!pickupSiteName || !pickupAddress){
      toast.error("Some fields are empty, Please try again !!");
    }
    else{
      databases.createDocument(
        'AppwriteCloudFEApp',
        '65ea946debea533b0667',
        ID.unique(),
        {
         distance:distance,
         pickupSiteName:pickupSiteName,
         pickupAddress:pickupAddress,
          createdAt:new Date().toLocaleString(),
          createdBy:Cookies.get("userName")

        }
      ).then((response)=>{
        toast.success("Pickup Site created sucessfully");
      }).catch((error)=>{
        toast.error("Error saving data, Please try again later !!");
        console.log(error)
      })
    }
  }



  return (
    <div className=''>
    <ToastContainer/>
    <div className='flex flex-wrap justify-between max-sm:justify-center'>
        <div className=' flex flex-col w-1/2 max-sm:w-full'>
            <div className='p-2 max-sm:w-full max-sm:flex  max-sm:flex-col'>
                <label className='m-2 sm:float-left'>Pickup Site Name</label>
                <input onChange={(e)=>{setPickupSiteName(e.target.value)}} value={pickupSiteName} className='m-2 p-2 sm:float-right sm:w-72 rounded-lg'></input>
            </div>
            <div className='p-2 max-sm:w-full max-sm:flex max-sm:flex-col'>
                <label className='m-2 sm:float-left'>Address</label>
                <input onChange={(e)=>{setPickupAddress(e.target.value)}} value={pickupAddress} className='m-2 sm:float-right sm:w-72 p-2 rounded-lg'></input>
            </div>
           
           
            
            
        </div>
        <div className='flex flex-col w-1/2 max-sm:w-full'>
        <div className='p-2 max-sm:w-full max-sm:flex  max-sm:flex-col'>
                <label className='m-2 align-middle sm:float-left'>Display Name</label>
                <input onChange={(e)=>{setDistance(e.target.value)}} value={distance}  className='m-2 p-2 sm:float-right sm:w-72 rounded-lg'></input>
            </div>
           
            
         
            
        </div>
    </div>
    <div>
      <button onClick={handlePickupSiteCreation} className='m-2 p-2 bg-blue-400 rounded-lg px-10 text-white hover:bg-slate-200 hover:text-black border-2 border-blue-400'>Submit</button>
    </div>
</div>
  )
}

export default PickupSiteCreation