import React, { useEffect, useState } from 'react'
import { CCarousel, CCarouselItem,CImage } from '@coreui/react'
import ReactImg from '../assets/react-sample.jpg'
import logo from '../assets/maatyrelogo.jpg'
import MichelinImg from '../assets/michelin-banner.png'
import tyreImg from '../assets/tyre-banner.jpeg'
import { Link, useNavigate } from 'react-router-dom'
import {  Account, Databases,ID } from "appwrite";
import appwriteClient from "../appwrite.config";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { handler } from 'tailwindcss-animate';

  


const Login = () => {

  const databases = new Databases(appwriteClient);


  const navigate = useNavigate();
  const account = new Account(appwriteClient);
  const [username,setUserName]=useState();
  const [password,setPassword]=useState();
  const [loading,setLoading]=useState(false);

  
    account.listSessions().then((response)=>{
    navigate('/', { replace: true })
   }).catch((error)=>{
console.log(error)
   })
 






  const handelLogin=(e)=>{
    setLoading(true)
    e.preventDefault();
    if(username && password){
    account.createEmailSession(username, password).then((response)=>{
     
      Cookies.set('sessionId',response.$id)
      Cookies.set('userId', response.userId);
      Cookies.set('timestamp',new Date().toISOString());
      Cookies.set('uid',response.providerUid);
      account.get(response.userId).then((responseUserDetails)=>{
       
        databases.createDocument(
          'AppwriteCloudFEApp',
          'LoginRecords',
          ID.unique(),
          {
            userId:responseUserDetails.$id,
            userProviderUid:responseUserDetails.email,
            userDisplayName:responseUserDetails.name,
            loginDate:new Date().getFullYear()+"/"+ (parseInt(new Date().getMonth())+1)+"/"+new Date().getDate(),
            loginTime:new Date().getHours()+":"+new Date().getMinutes(),
            loginOn:"Web App"
          }).then((successResponse)=>{
            Cookies.set('recordId', successResponse.$id);
            Cookies.set("userName",responseUserDetails.name)
                  navigate('/', { replace: true })
toast.success("LogedIn Succsfully !!");
          }).catch((error)=>{
            toast.error("An error occured, PLease try again later");
            console.log(error);
          })
      }).catch((error)=>{
        toast.error("An error occured, PLease try again later")
        console.log(error)})
     
     


setLoading(false);

    }).catch((error)=>{
      toast.error("Unable to login, Please try again");
      console.log(error);
      setLoading(false);
    })
  }
  else{
    toast.error("Fields are empty, PLease try again !!")
    setLoading(false);
  }
  }


  return (
    <div className='flex flex-wrap flex-row'>
        <div className='w-1/2 max-sm:w-full max-sm:h-1/3 h-screen'>
        <div className='h-screen max-sm:h-1/3'>
 
    <img width={"50%"} className="d-block w-full object-fill  h-screen max-sm:h-2/5" src={logo} alt="slide 1" />
  
 
</div>
        </div>
        <div className='w-2/5  m-auto max-sm:h-2/5 max-sm:w-full p-5 shadow-xl'>
          <ToastContainer/>
          <div className=' w-full align-middle m-auto flex-col h-fit flex'>
          <h2 className='m-auto text-3xl my-2'>Welcome Back</h2>
      <form onSubmit={(e)=>{handelLogin(e)}} className='align-middle flex-col flex justify-center m-auto'>
        <label className='mt-2 text-xl m-auto'>Username</label>
        <input value={username} type='email' onChange={(e)=>{setUserName(e.target.value)}} size={15} className='m-auto p-1 px-2 text-xl border-blue-400 border-2 rounded-lg ' placeholder='Enter Username'></input>
        <label className='mt-2 text-xl m-auto'>Password</label>
        <input size={15} value={password} onChange={(e)=>{setPassword(e.target.value)}} className='m-auto p-1 px-2 text-xl border-blue-400 border-2 rounded-lg' placeholder='Enter Password' type='password'></input>
        <button disabled={loading} className='mt-4 p-1 text-xl border-blue-400 border-2 rounded-lg bg-blue-400 text-white hover:text-black hover:bg-white ' onClick={(e)=>{handelLogin(e)}} > Login</button>
      </form>
<Link className='text-blue-600 underline' to={"/forgetpassword"}>Forget Password ?</Link>
        </div>
        </div>
    </div>
  )
}

export default Login