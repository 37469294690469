import React, { useState,useEffect } from 'react'
import { Databases, Query , ID }  from 'appwrite'
import { ToastContainer, toast } from 'react-toastify'
import appwriteClient from '../appwrite.config'
import 'react-toastify/dist/ReactToastify.css'
import Cookies from 'js-cookie'

const PickupRequestUpdationCard = (props) => {
    const [pickupRequestNumber,setPickupRequestNumber]=useState("");
    const [fleetName,setFleetName]=useState("");
    const [pickupSite,setPickupSite]=useState("");
    const [status,setStatus]=useState("");
    const [remarks,setRemarks]=useState("");
    const [createdBy,setCreatedBy]=useState("");
    const [pickupDate,setPickupDate]=useState("");
    const [pickupTime,setPickupTime]=useState("");
    const [createdOn,setCreatedOn]=useState("");
    const [tyreSizes,setTyreSizes]=useState();
    const [tyreQuantity,setTyreQuantity]=useState();

    const databases = new Databases(appwriteClient);

    useEffect(()=>{
        setCreatedBy(Cookies.get("userName"));
    },[])


    useEffect(()=>{
        if(props.isUpdation){
             databases.getDocument( 'AppwriteCloudFEApp',
             'PickupRequest',props.id).then((response)=>{
                setPickupRequestNumber(response.pickupRequestNumber);
                setFleetName(response.fleetName);
                setStatus(response.status);
                setPickupSite(response.pickupSite);
                setRemarks(response.remarks);
                setPickupDate(response.pickupDate);
                setPickupTime(response.pickupTime);
                setTyreSizes(response.tyreSizes);
                setTyreQuantity(response.tyreQuantity);
             }).catch((error)=>{
                toast.error("An error occurred, Please try again");
                console.log(error);
             })
        }
    },[])


    
    const updatePickupRequest=()=>{
        if(!pickupRequestNumber || !fleetName||!pickupSite|| !status|| !tyreSizes ||!tyreQuantity||!pickupDate||!pickupTime||!createdBy){
            toast.error("Some fields are empty, Please try again !!");
          }
          else{
            databases.updateDocument(
                'AppwriteCloudFEApp',
                'PickupRequest',
                props.id,
                {
                    pickupRequestNumber:pickupRequestNumber,
                    fleetName:fleetName,
                    pickupSite:pickupSite,
                    status:status,
                    pickupDate:pickupDate,
                    pickupTime:pickupTime,
                    remarks:remarks,
                    tyreSizes:tyreSizes,
                    tyreQuantity:tyreQuantity,
                    createdBy:createdBy,
                    createdOn:new Date().toLocaleString()
                }).then((response)=>{
                    toast.success("Pickup Request updated sucessfully !!")
                    window.location.reload();
                }).catch((error)=>{
                    toast.error("Error occurred while updating Pickup Request, Please try agan later !!")
                    console.log(error);
                })
          }
    }

  return (
    <div className=''>
    <ToastContainer/>
    <div className='flex flex-wrap justify-between max-sm:justify-center'>
        <div className=' flex flex-col w-1/2 max-sm:w-full'>
            <div className='p-2 max-sm:w-full max-sm:flex  max-sm:flex-col'>
                <label className='m-2 sm:float-left'>Pickup Request Number</label>
                <input onChange={(e)=>{setPickupRequestNumber(e.target.value)}} value={pickupRequestNumber} className='m-2 p-2 sm:float-right sm:w-72 rounded-lg'></input>
            </div>
            <div className='p-2 max-sm:w-full max-sm:flex max-sm:flex-col'>
                <label className='m-2 sm:float-left'>Fleet Name</label>
                <input onChange={(e)=>{setFleetName(e.target.value.trim())}} value={fleetName} className='m-2 sm:float-right sm:w-72 p-2 rounded-lg'></input>
            </div>
            <div className='p-2 max-sm:w-full max-sm:flex max-sm:flex-col'>
                <label className='m-2 sm:float-left'>Pickup Site</label>
                <input value={pickupSite} onChange={(e)=>{setPickupSite(e.target.value.trim())}} className='m-2 sm:float-right sm:w-72 p-2 rounded-lg'></input>
            </div>
            <div className='p-2 max-sm:w-full max-sm:flex max-sm:flex-col'>
                <label className='m-2 sm:float-left'>Status</label>
                
                <select value={status} onChange={(e)=>{setStatus(e.target.value)}} className='m-2 sm:float-right sm:w-72 p-2 rounded-lg'>
  <option value="PickupRequested">PickupRequested</option>
  <option value="TripAlloted">TripAlloted</option>
<option value="TripStarted">TripStarted</option>
<option value="TripEnded">TripEnded</option>
<option value="Cancelled">Cancelled</option>
</select>
            </div>
        </div>
        <div className='flex flex-col w-1/2 max-sm:w-full'>
        <div className='p-2 max-sm:w-full max-sm:flex  max-sm:flex-col'>
                <label className='m-2 align-middle sm:float-left'>Pickup Date</label>
                <input onChange={(e)=>{setPickupDate(e.target.value)}} value={pickupDate} className='m-2 p-2 sm:float-right sm:w-72 rounded-lg'></input>
            </div>
            <div className='p-2 max-sm:w-full max-sm:flex  max-sm:flex-col'>
                <label className='m-2 align-middle sm:float-left'>Pickup Time</label>
                <input onChange={(e)=>{setPickupTime(e.target.value)}} value={pickupTime} className='m-2 p-2 sm:float-right sm:w-72 rounded-lg'></input>
            </div>
            <div className='p-2 max-sm:w-full max-sm:flex  max-sm:flex-col'>
                <label className='m-2 align-middle sm:float-left'>Tyre Sizes</label>
                <input onChange={(e)=>{setTyreSizes(e.target.value.split(","))}} value={tyreSizes} className='m-2 p-2 sm:float-right sm:w-72 rounded-lg'></input>
            </div>
            <div className='p-2 max-sm:w-full max-sm:flex  max-sm:flex-col'>
                <label className='m-2 align-middle sm:float-left'>Tyre Quantity</label>
                <input onChange={(e)=>{setTyreQuantity(e.target.value.split(","));console.log(tyreQuantity)}} value={tyreQuantity} className='m-2 p-2 sm:float-right sm:w-72 rounded-lg'></input>
            </div>
          
        </div>
    </div>
    <div>
      <button  className='m-2 p-2 bg-blue-400 rounded-lg px-10 text-white hover:bg-slate-200 hover:text-black border-2 border-blue-400' onClick={updatePickupRequest}>Submit</button>
    </div>
</div>
  )
}

export default PickupRequestUpdationCard