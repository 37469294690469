import React from 'react'
import logo from '../assets/maatyrelogo.jpg'
import { AiOutlineLogout } from "react-icons/ai";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import {  Account, Databases,ID } from "appwrite";
import appwriteClient from "../appwrite.config";

const Navbar = (props) => {

    const account = new Account(appwriteClient);
    const databases=new Databases(appwriteClient);
    const navigate = useNavigate();

    //const result = await account.deleteSessions();
    

    const handleLogout =async ()=> {
   
    await databases.updateDocument(
    'AppwriteCloudFEApp',
    'LoginRecords',
    Cookies.get("recordId"),{
        logoutDate:new Date().getFullYear()+"/"+(new Date().getMonth()+1)+"/"+new Date().getDate(),
        logoutTime:new Date().getHours()+":"+new Date().getMinutes()
    }
).then((response)=>{
        account.deleteSession('current').then((response)=>{
        Cookies.remove("userId");
        Cookies.remove('timestamp');
        Cookies.remove('uid');
        Cookies.remove('recordId');
        Cookies.remove("userName");
        toast.success("Logged Out successfully !!");
        navigate('/login', { replace: true })

       
     }).catch((error)=>{
        toast.error("Error logging out, Please try again !!")
        console.log(error)

     })
}).catch((error)=>{
    account.deleteSession('current').then((response)=>{
        Cookies.remove("userId");
        Cookies.remove('timestamp');
        Cookies.remove('uid');
        Cookies.remove('recordId');
        Cookies.remove("userName");
        toast.success("Logged Out successfully !!");
        navigate('/login', { replace: true })

       
     }).catch((error)=>{
        toast.error("Error logging out, Please try again !!")
        console.log(error)

     })
    toast.error("Error Saving data, Please try again !!")
    console.log(error)
})

    }

  return (
    <>
    <ToastContainer/>
    <div className='w-full bg-blue-400 flex flex-row justify-between text-white' >
        <Link to={"/"}>
    <img height={50} width={60} className='max-sm:ml-10 hover:cursor-pointer' src={logo} alt="logo"></img>
    </Link>
    <div className=' text-2xl my-2  w-fit font-bold font-serif   max-sm:mb-0 max-sm:text-xl '>
  
 
    {props.page}
 </div>
 <button className='mr-7' onClick={handleLogout}><AiOutlineLogout size={25}  color='white'/></button>
       </div>
    </>
  )
}

export default Navbar