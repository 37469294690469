/* eslint-disable no-loop-func */
import React, { useState,useEffect } from 'react'
import { Databases, Query , ID }  from 'appwrite'
import { ToastContainer, toast } from 'react-toastify'
import appwriteClient from '../appwrite.config'
import 'react-toastify/dist/ReactToastify.css'
import xlsx from "xlsx";
import Cookies from 'js-cookie'


const InvoiceImportCard = (props) => {

    const [invNumber,setInvNumber]=useState("");
    const [fleetName,setFleetName]=useState("");
    const [tyreSizes,setTyreSizes]=useState([]);
    const [uniqueTyreSizes,setUniqueTyreSizes]=useState([]);
    const [tyreQuantity,setTyreQuantity]=useState([]);
    const [invDate,setInvDate]=useState("");
    const [invAmount,setInvAmount]=useState();
    const [createdBy,setCreatedBy]=useState("");
    const [status,setStatus]=useState("InvoiceInitiated");
    const [createdOn,setCreatedOn]=useState("");

    const databases = new Databases(appwriteClient);
    var i =14;
    var m=0;
    var n=0;
    var h=0;
    var j=0;
    var start = false;
    var count = 0;
    var tsname1;
    var tsname2="No Tyre";
    var tsname3="No Tyre";
    var tsname4="No Tyre";
    var tsname5="No Tyre";
    var tsname6="No Tyre";
    var tq1=0;
    var tq2=0;
    var tq3=0;
    var tq4=0;
    var tq5=0;
    var tq6=0;
    
    const tyresize = [];
    const uniquetyresize=[];
    const tyrequantity=[];

    useEffect(()=>{
        setCreatedBy(Cookies.get("userName"));
    },[])

    useEffect(()=>{
        if(props.isUpdation){
             databases.getDocument( 'AppwriteCloudFEApp',
             'Invoice',props.id).then((response)=>{
                setInvNumber(response.invNumber);
                setFleetName(response.fleetName);
                setStatus(response.Status);
                setInvDate(response.invDate);
                setInvAmount(response.invAmount);
                setUniqueTyreSizes(response.tyreSizes);
                setTyreQuantity(response.tyreQuantity);
             }).catch((error)=>{
                toast.error("An error occurred, Please try again");
                console.log(error);
             })
        }
    },[])


    const updateInvoice=()=>{
        if(!invNumber || !fleetName|| !uniqueTyreSizes ||!tyreQuantity||!invDate||!invAmount||!createdBy){
            toast.error("Some fields are empty, Please try again !!");
          }
          else{
            databases.updateDocument(
                'AppwriteCloudFEApp',
                'Invoice',
                props.id,
                {
                    invNumber:invNumber,
                    fleetName:fleetName,
                    invDate:invDate,
                    Status:status,
                    invAmount:parseInt(invAmount),
                    tyreSizes:uniqueTyreSizes,
                    tyreQuantity:tyreQuantity,
                    createdBy:createdBy,
                    createdOn:new Date().toLocaleString()
                }).then((response)=>{
                    toast.success("Invoice updated sucessfully !!")
                    window.location.reload();
                }).catch((error)=>{
                    toast.error("Error occurred while updating invoice, Please try agan later !!")
                    console.log(error);
                })
          }
    }

    const uploadInvoice=()=>{
        if(!invNumber || !fleetName||!uniqueTyreSizes ||!tyreQuantity||!invDate||!invAmount||!createdBy){
            toast.error("Some fields are empty, Please try again !!");
          }
          else{
            databases.createDocument(
                'AppwriteCloudFEApp',
                'Invoice',
                ID.unique(),
                {
                    invNumber:invNumber,
                    fleetName:fleetName,
                    invDate:invDate,
                    Status:status,
                    invAmount:parseInt(invAmount),
                    tyreSizes:uniqueTyreSizes,
                    tyreQuantity:tyreQuantity,
                    createdBy:createdBy,
                    createdOn:new Date().toISOString()
                }).then((response)=>{
                    toast.success("Invoice uploaded sucessfully !!")
                    window.location.reload();
                }).catch((error)=>{
                    toast.error("Error occurred while uploading invoice, Please try agan later !!")
                    console.log(error);
                })
          }
    }
    
    const readUploadFile = (e) => {
        e.preventDefault();
        tq1=0;
        tq2=0;
        tq3=0;
        tq4=0;
        tq5=0;
        tq6=0;
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = xlsx.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = xlsx.utils.sheet_to_json(worksheet);
                console.log(json);
                  console.log("Excel Decoding started")

                json.map((invdata)=>{
                    if(invdata.__rowNum__===8){
                        setFleetName(invdata.__EMPTY_2);
                        console.log(fleetName);
                    }
                })
                json.map((invdata)=>{
                    if(invdata.__rowNum__===8){
                        setInvNumber(invdata.__EMPTY_7);
                        console.log(invNumber);
                    }
                })
                json.map((invdata)=>{
                    if(invdata.__rowNum__===9){
                        setInvDate(invdata.__EMPTY_7);
                        console.log(invDate);
                    }
                })
                json.map((invdata)=>{
                    if(invdata.__rowNum__===68){
                        setInvAmount(invdata.__EMPTY_8);
                        console.log(invAmount);
                    }
                })
    
                while (i<62) {
                    json.map((invdata)=>{
                        if(invdata.__rowNum__==i){
                            if(invdata.__EMPTY_1!==''){
    
                                
                               if(invdata.__EMPTY_3!=undefined && invdata.__EMPTY_3!="IGST" && invdata.__EMPTY_3!="Rate" && invdata.__EMPTY_3!=0){
                                tyresize.push(invdata.__EMPTY_3);
                                console.log(tyresize);
                            }

                                i++;
                            
                           
                            }
                        }
                    })
                }
                

                for (h = 0; h < tyresize.length; h++) {
                    for (j = 0; j < uniquetyresize.length; j++) {
                          if ( tyresize[h] == uniquetyresize[j] ) {
                               start = true;
                          }
                     }
                    count++;
               if (count == 1 && start == false) {
                       uniquetyresize.push(tyresize[h]);
                   }
                   start = false;
                   count = 0;

                   console.log(uniquetyresize)
               }



               if(uniquetyresize[0]!=undefined){
                tsname1=uniquetyresize[0];
               }
               
               if(uniquetyresize[1]!=undefined){
                tsname2=uniquetyresize[1];
               }

               if(uniquetyresize[2]!=undefined){
                tsname3=uniquetyresize[2];
               }

               if(uniquetyresize[3]!=undefined){
                tsname4=uniquetyresize[3];
               }

               if(uniquetyresize[4]!=undefined){
                tsname5=uniquetyresize[4];
               }

               if(uniquetyresize[5]!=undefined){
                tsname6=uniquetyresize[5];
               }

              




            
                if(uniquetyresize[0]!=undefined){
                    
                        for(n=0; n<tyresize.length;n++){

                            if(uniquetyresize[0]==tyresize[n]){
                                tq1++;

                            }
                        }

                    
                        tyrequantity.push(tq1);
                

                    
                }


                if(uniquetyresize[1]!=undefined){
                    
                    for(n=0; n<tyresize.length;n++){

                        if(uniquetyresize[1]==tyresize[n]){
                            tq2++;

                        }
                    }
              

tyrequantity.push(tq2);
                
            }


            if(uniquetyresize[2]!=undefined){
                    
                for(n=0; n<tyresize.length;n++){

                    if(uniquetyresize[2]==tyresize[n]){
                        tq3++;

                    }
                }


                tyrequantity.push(tq3);
                
        }


        if(uniquetyresize[3]!=undefined){
                    
            for(n=0; n<tyresize.length;n++){

                if(uniquetyresize[3]==tyresize[n]){
                    tq4++;

                }
            }

            tyrequantity.push(tq4);
                
    }


    if(uniquetyresize[4]!=undefined){
                    
        for(n=0; n<tyresize.length;n++){

            if(uniquetyresize[4]==tyresize[n]){
                tq5++;

            }
        }
        tyrequantity.push(tq5);
                     
}



if(uniquetyresize[5]!=undefined){
                    
    for(n=0; n<tyresize.length;n++){

        if(uniquetyresize[5]==tyresize[n]){
            tq6++;

        }
    }
    tyrequantity.push(tq6);
                
}




                
                

                
setUniqueTyreSizes(uniquetyresize);
setTyreQuantity(tyrequantity)

            }
               reader.readAsArrayBuffer(e.target.files[0]);
            }}
           
  return (
    <div className=''>
    <ToastContainer/>
    <div className='flex flex-wrap justify-between max-sm:justify-center'>
        <div className=' flex flex-col w-1/2 max-sm:w-full'>
            <div className='p-2 max-sm:w-full max-sm:flex  max-sm:flex-col'>
                <label className='m-2 sm:float-left'>Invoice No.</label>
                <input onChange={(e)=>{setInvNumber(e.target.value)}} value={invNumber} className='m-2 p-2 sm:float-right sm:w-72 rounded-lg'></input>
            </div>
            <div className='p-2 max-sm:w-full max-sm:flex max-sm:flex-col'>
                <label className='m-2 sm:float-left'>Fleet Name</label>
                <input onChange={(e)=>{setFleetName(e.target.value.trim())}} value={fleetName} className='m-2 sm:float-right sm:w-72 p-2 rounded-lg'></input>
            </div>
            <div className='p-2 max-sm:w-full max-sm:flex max-sm:flex-col'>
                <label className='m-2 sm:float-left'>Date</label>
                <input type='date' value={invDate} onChange={(e)=>{setInvDate(e.target.value.trim())}} className='m-2 sm:float-right sm:w-72 p-2 rounded-lg'></input>
            </div>
            <div className='p-2 max-sm:w-full max-sm:flex max-sm:flex-col'>
                <label className='m-2 sm:float-left'>Status</label>
                
                <select value={status} onChange={(e)=>{setStatus(e.target.value)}} className='m-2 sm:float-right sm:w-72 p-2 rounded-lg'>
  <option value="InvoiceInitiated">InvoiceInitiated</option>
  <option value="TripAlloted">TripAlloted</option>
<option value="TripStarted">TripStarted</option>
<option value="Delivered">Delivered</option>
<option value="Canceled">Canceled</option>
</select>
            </div>
        </div>
        <div className='flex flex-col w-1/2 max-sm:w-full'>
        <div className='p-2 max-sm:w-full max-sm:flex  max-sm:flex-col'>
                <label className='m-2 align-middle sm:float-left'>Amount</label>
                <input onChange={(e)=>{setInvAmount(e.target.value)}} value={invAmount} className='m-2 p-2 sm:float-right sm:w-72 rounded-lg'></input>
            </div>
            <div className='p-2 max-sm:w-full max-sm:flex  max-sm:flex-col'>
                <label className='m-2 align-middle sm:float-left'>Tyre Sizes</label>
                <input onChange={(e)=>{setUniqueTyreSizes(e.target.value.split(","))}} value={uniqueTyreSizes} className='m-2 p-2 sm:float-right sm:w-72 rounded-lg'></input>
            </div>
            <div className='p-2 max-sm:w-full max-sm:flex  max-sm:flex-col'>
                <label className='m-2 align-middle sm:float-left'>Tyre Quantity</label>
                <input onChange={(e)=>{setTyreQuantity(e.target.value.split(","))}} value={tyreQuantity} className='m-2 p-2 sm:float-right sm:w-72 rounded-lg'></input>
            </div>
            <div className='p-2 max-sm:w-full max-sm:flex max-sm:flex-col'>
                <label className='m-2 sm:float-left'>Invoice (Excel)</label>
                <input type="file"
                className='m-2 sm:float-right sm:w-72 p-2 rounded-lg'
                name="upload"
                id="upload"
                onChange={readUploadFile}
                ></input>
            </div>
        </div>
    </div>
    <div>
      <button  className='m-2 p-2 bg-blue-400 rounded-lg px-10 text-white hover:bg-slate-200 hover:text-black border-2 border-blue-400' onClick={props.isUpdation?updateInvoice:uploadInvoice}>Submit</button>
    </div>
</div>
  )
}

export default InvoiceImportCard