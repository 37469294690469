import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import logo from '../assets/mtrwologo.png'
import { Account, Databases, ID, Query, Storage }  from 'appwrite';
import appwriteClient from '../appwrite.config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsWhatsapp } from "react-icons/bs";
import { MdPictureAsPdf } from "react-icons/md";
import { SiMicrosoftexcel } from "react-icons/si";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Cloudinary } from '@cloudinary/url-gen';
import { jsPDF } from "jspdf";
import * as XLSX from 'xlsx'
import axios from 'axios';





function WorkOrderPreview() {
    const { id } = useParams();
    const databases = new Databases(appwriteClient);
    const storage = new Storage(appwriteClient);
    const [woData,setWoData]=useState({});
    const [tyreData,setTyreData]=useState([]);
    const [whatsappNo,setWhatsappNo]=useState();
    const [isLoggedIn,setIsLoggedIn]=useState();
    const [isLoading,setIsLoading]=useState(true);
    
    const sendMessage= async ()=>{
if(id && whatsappNo){
    const instance = axios.create({
       
        baseURL: `https://feappserver-1rzw.onrender.com/api/v1/`,
        headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'},
        credentials: 'include',
      })
      try {
        instance.post(`workOrder/getWorkOrder`,{
            workOrderNumber:id,
            user:"test",
            whatsaapNumber:whatsappNo
        }).then((response)=>{
            toast.success("Message sent sucessfully !!")
          console.log(response);
        }).catch((error)=>{
          console.log(error)
          toast.error(error.message);
        })
      } catch (error) {
        toast.error("Error sending message, Please try again !!");
        console.log(error)
      }   
    //http://localhost:8000/api/v1/workOrder/getWorkOrder
    // whatsappNo.split(",").map((no)=>{
      
    //     axios.get(`https://panel.rapiwha.com/send_message.php?apikey=F6GM19T30HLHXGO2MEJL&number=91${no}&text=Hey ${woData.fleetName}, Work Order ${id} is generated for you. Follow the link below to download https://feappv2.web.app/workorderPreview/${id}`).then(function (response) {
    //         toast.success("Message sent sucessfully !!")
    //       }).catch(function (error){
    //           toast.error('An error occurred while sending Whatsapp Message !!')
    //           console.log(error)
    //       })
    // })
    
}
else{
    toast.error('Please enter Whataspp number to send message !!')
}
    }
    const account = new Account(appwriteClient);
    const getLoginStatus = async () =>{
        await account.get().then((response)=>{
setIsLoggedIn(true)
        }).catch((error)=>{
            setIsLoggedIn(false);
        })

    }

    useEffect(()=>{
        getLoginStatus();
    },[])
   

     // Create a Cloudinary instance and set your cloud name.

 const getWorkOrder = async () =>{
  await databases.listDocuments(
                'AppwriteCloudFEApp',
                'WorkOrder',
                [
                    Query.equal("workOrderNumber",id)
                ]
            ).then((response)=>{
            setWoData(response.documents[0]);
            
            }).catch((error)=>{
            toast.error("An error occurred, Please try again later !!");
            })
 }


    useEffect (()=>{
getWorkOrder();
           
        },[])

const getTyreDetails = async ()=>{
   await databases.listDocuments(
            'AppwriteCloudFEApp',
    'WorkOrderTyreDetails',
    [
        Query.equal("workOrderNo",id),
        Query.limit(30)
    ]
        ).then((response)=>{
        setTyreData(response.documents)
        setIsLoading(false)
        }).catch((error)=>{
            toast.error("An error occurred, Please try again later !!")
        })
}

    useEffect(()=>{
     getTyreDetails();
    },[])

    const getWhatsappNumber = async () =>{
    await databases.listDocuments(
            'AppwriteCloudFEApp',
            '65ea96670e2c9740a984',
            [
                Query.equal("fleetname",woData.fleetName),
                Query.limit(30)
            ]
        ).then((response)=>{
            if(response.total!==0){
             setWhatsappNo(response.documents[0].whatsappNumber)   
            } 
        }).catch((error)=>{
            toast.error("An error occurred while getting Whatsapp Number");
            console.log(error)
        })
    }
    useEffect(()=>{
       getWhatsappNumber()
    },[woData])



    // PDF Download
    const handleGeneratePdf = async () => {
        const doc = new jsPDF(
           "p",'px', [800, 780]
    
       
       );
       doc.html(  document.querySelector("#printableDiv"),{
           callback:function  (pdf)  {
               pdf.setFontSize(1);
               pdf.save(`WorkOrder ${woData.fleetName} ${woData.workOrderNumber}.pdf`);
                        
              
           }
       })
       console.log(doc.output(),"ascii");
    }

    // Excel Export
    const excelexport =  () => {
        
        var wb = XLSX.utils.table_to_book(document.getElementById("excelexport"));
        /* Export to file (start a download) */
        XLSX.writeFile(wb, `WorkOrder ${woData.fleetName} ${woData.workOrderNumber}.xlsx`);
    
    
    }



  return (
    <div className="m-2 border-yellow-300 h-screen">
        <div>
        <div className='absolute left-2 flex bg-white flex-col'>
        <ToastContainer />
        <Popup trigger={<button> <BsWhatsapp size={30} className=' m-2 ml-3' color='#128c7e'/></button>} position="right top">
    <div className='justify-center'>
        <label className='m-3'>Whatsapp Number</label>
        <input value={whatsappNo}  onChange={(e)=>{
               setWhatsappNo(e.target.value)
            
        }}    className='m-0.5 p-2 rounded-lg w-full mr-1 border-white' placeholder='Enter Whatsapp No'></input>
        <button onClick={sendMessage} className='justify-center p-4 py-2 hover:border-green-400 hover:border-2 hover:bg-white hover:text-black rounded-lg mx-0.5 my-3 bg-green-400 text-white'>Send</button>
    </div>
  </Popup>
<button className='hover:cursor-pointer' onClick={handleGeneratePdf}>
<MdPictureAsPdf size={35} className='m-2' color='red' />
</button>
{isLoggedIn&&
<button onClick={excelexport}>
<SiMicrosoftexcel size={30} className='m-2' color='green'/>
</button>
}
    </div>
        </div>
         <ToastContainer /> 
    <div className='m-auto border-2  p-2 rounded-lg' id='printableDiv' style={{width:"781px",minHeight:"798px"}}>
<img src={logo} width={"98%"} className='m-auto my-2' alt='logo'></img>
<div className='flex'>
<div className='w-3/5'>
<div>
Work Order No :&nbsp;&nbsp; 
{woData.workOrderNumber}
</div>
<div>
Fleet Name :&nbsp;&nbsp; 
{woData.fleetName}
</div>
<div>
Pickup Date & Time :&nbsp;&nbsp; 
{woData.date?.slice(8,10)+"/"+woData.date?.slice(5,7)+"/"+woData.date?.slice(0,4)+" "+woData.time }
</div>
</div>
<div className='w-2/5'>
<div>
    Pickup Site :&nbsp;&nbsp;
    {woData.pickupSite}
</div>
<div>
    Driver & Vehicle :&nbsp;&nbsp;
    {woData.driver + ", "+ woData.vehicle}
</div>
<div>
    Pickup Request No :&nbsp;&nbsp;
    {woData.prno}
</div>
</div>
</div>
<div className='m-2 p-2'>
    <table className='m-auto'>
        <thead>
<tr  className='bg-blue-400 text-white'>
    <th className='text-center px-4 py-4 border-x-2 '>Tyre ID</th>
    <th className='text-center px-4 py-4 border-x-2 '>Tyre Make</th>
    <th className='text-center px-4 py-4 border-x-2 '>Tyre Size</th>
    <th className='text-center px-4 py-4 border-x-2 '>Tyre Serial No</th>
    <th className='text-center px-4 py-4 border-x-2 '>Remarks</th>
</tr>
        </thead>
        <tbody>
{tyreData.map((data)=>{
    return(
        <tr key={data.tyreId} className=''>
        <td className='text-center px-2 '>{data.tyreId}</td>
        <td className='text-center px-2 border-x-2'>{data.tyreMake}</td>
        <td className='text-center px-2 border-x-2'>{data.tyreSize}</td>
        <td className='text-center px-2 border-x-2'>{data.tyreSerialNo}</td>
        <td className='text-center px-2 '>{data.remarks}</td>
        </tr>
    )
})}
        </tbody>
        
    </table>
   
</div>
<div className='mx-60 max-sm:hidden' style={{position:"absolute",bottom:"10px"}}>
    This is a computer generated document.
    </div>
    </div>
    <div className='hidden'>
        <table id="excelexport">
            <tbody>
                <tr>
                <td style={{ textAlign: "center"}} colSpan={10}>Work Order</td>
                </tr>
                <tr>
                        <td style={{ textAlign: "center"}}>Work Order No.:</td>
                        <td style={{ textAlign: "center"}} colSpan={2}>{woData.workOrderNumber}</td>
                        <td style={{ textAlign: "center"}}>P.R. No.:</td>
                        <td style={{ textAlign: "center"}}>{woData.prno}</td> 
                    </tr>
                    <tr>
                        <td style={{ textAlign: "center"}}>Fleet Name:</td>
                        <td style={{ textAlign: "center"}} colSpan={2}>{woData.fleetName}</td>
                        <td style={{ textAlign: "center"}}>Driver Name:</td>
                        <td style={{ textAlign: "center"}}>{woData.driver}</td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: "center"}}>Pickup Site</td>
                        <td style={{ textAlign: "center"}} colSpan={2}>{woData.pickupSite}</td>
                        <td style={{ textAlign: "center"}}>Vehicle No.</td>
                        <td style={{ textAlign: "center"}}>{woData.vehicle}</td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: "center"}} colSpan={3}>Pickup Date and Time</td>
                        <td style={{ textAlign: "center"}} colSpan={2}>{woData.date+" "+woData.time}</td>
                    </tr>
<tr>
    <td>Tyre Id</td>
    <td>Tyre Make</td>
    <td>Tyre Size</td>
    <td>Tyre Serial No</td>
    <td>Remarks</td>
</tr>
                    {tyreData.map((data)=>{
    return(
        <tr key={data.tyreId} className=''>
        <td className='text-center '>{data.tyreId}</td>
        <td className='text-center'>{data.tyreMake}</td>
        <td className='text-center'>{data.tyreSize}</td>
        <td className='text-center'>{data.tyreSerialNo}</td>
        <td className='text-center'>{data.remarks}</td>
        </tr>
    )
})}
            </tbody>
        </table>
    </div>
  
    </div>
  )
}

export default WorkOrderPreview