import { Client } from 'appwrite';



const appwriteClient = new Client();

appwriteClient
    .setEndpoint('https://cloud.appwrite.io/v1')
    .setProject('65cce78f9f82c6aaf82b');

    export default appwriteClient



