import React, { useEffect, useState } from 'react'
import appwriteClient from '../appwrite.config';
import { Databases, ID, Query } from 'appwrite';
import { ToastContainer,toast } from 'react-toastify';
import Select from 'react-select'
import Cookies from 'js-cookie'

const MonthlyRunningForm = () => {
    const [fleetNameDropdown,setFleetNameDropdown] = useState([]);
    const [vehicleRegNoDropdown,setVehicleRegNoDropdown] = useState([]);
    const [selectedFleetName,setSelectedFleetName] = useState([]);
    const [selectedVehicleRegNo,setSelectedVehicleRegNo] = useState([]);
    const databases = new Databases(appwriteClient);
    const [data,setData] = useState({
        vehicleProfile:"",
        fleetProfile:"",
        month:"",
        kmsRun:undefined,
        remarks:"",
        
    })

    const handleMonthlyRunSubmit = () =>{
        if(!data.fleetProfile || !data.vehicleProfile || !data.month || !data.kmsRun){
            toast.error("Some fields are empty, Please try again later !!");
        }
        else{
            databases.createDocument(
                'AppwriteCloudFEApp',
                'MonthlyRunningData',
                ID.unique(),
                {
                    ...data,
                    createdBy:Cookies.get("userName"),
                    createdAt:new Date().toISOString(),
                    kmsRun:parseInt(data.kmsRun)
                }
            ).then((response)=>{
                toast.success("Monthly Running data uploaded sucessfully !!")
                    window.location.reload();
            }).catch((error)=>{
                console.log(error)
                toast.error("Error uploading Monthly Running data, Please try again later !!")
            })
        }
    }
    
    useEffect(()=>{
        databases.listDocuments(
            'AppwriteCloudFEApp',
            '65ea96670e2c9740a984',
            [
                Query.limit(500),
                
            ]
        ).then((response)=>{
            const fleetNameDropdownarr=[];
            response.documents.map((data) => {
                fleetNameDropdownarr.push(
                {value:data.$id,label:data.fleetname}
              ) 
            })
            setFleetNameDropdown(fleetNameDropdownarr);
        }).catch((error)=>{
            toast.error("An error occurred while getting Fleetname dropdown, Please try again later !!");
            console.log(error);
        })
    },[])

    
    useEffect(()=>{
        if(selectedFleetName.length>0){
            
         databases.listDocuments(
             'AppwriteCloudFEApp',
             'VehicleProfile',
             [
                 Query.equal("fleetname",selectedFleetName),
                 Query.limit(1000)
                 
             ]
         ).then((response)=>{
             const vehicleDropdownArr=[]
            
             response.documents.map((data)=>{
                
               vehicleDropdownArr.push(
                 {value:data.$id,label:data.vehicleRegistrationNumber}
               ) 
             })
     
             setVehicleRegNoDropdown(vehicleDropdownArr)
         }).catch((error)=>{
             toast.error("An error occurred while getting Vehicle dropdown, Please try again later !!");
             console.log(error);
         })
        }
     },[selectedFleetName])


     const handleSubmit =async()=>{

        if(!data.fleetProfile || !data.vehicleProfile || !data.month || !data.kmsRun){
            toast.error("Some fields are empty, Please try again later !!");
        }

        
        else{

            await databases.listDocuments('AppwriteCloudFEApp',
                'MonthlyRunningData',[Query.equal("vehicleProfile",data.vehicleProfile),Query.equal("month",data.month)]).then(async(res)=>{
                    
                    if(res.total===0){
                        await databases.createDocument(
                            'AppwriteCloudFEApp',
                            'MonthlyRunningData',
                            ID.unique(),
                            {
                                ...data,
                                createdBy:Cookies.get("userName"),
                                createdOn:new Date().toLocaleString()
                            }
                        ).then(async(response)=>{
                            await databases.getDocument(
                                'AppwriteCloudFEApp',
                                'VehicleProfile',
                                selectedVehicleRegNo
                            ).then(async(resp)=>{
                                console.log(resp);
                                let dataVP = {};
                                if(!resp.monthlyrunningFrequency){
                                    
                                      dataVP={monthlyrunningFrequency:1,avgMonthlyRunning:parseInt(data.kmsRun/30)}
                                      console.log(dataVP);
                                    
                                }
                                else if(resp.monthlyrunningFrequency>=1){
                                    dataVP={monthlyrunningFrequency:resp.monthlyrunningFrequency+1,avgMonthlyRunning:parseInt(((resp.monthlyrunningFrequency*resp.avgMonthlyRunning)+(data.kmsRun/30))/(resp.monthlyrunningFrequency+1))}
                                    console.log(dataVP);
                                }

                                await databases.updateDocument(
                                    'AppwriteCloudFEApp',
                                    'VehicleProfile',
                                    selectedVehicleRegNo,
                                    dataVP
                                ).then((re)=>{
                                toast.success("Monthly Running data uploaded sucessfully !!")
                                window.location.reload();
                                }).catch((error)=>{
                                    console.log(error)
                                    toast.error("Error updating Vehicle Profile data, Please try again later !!")
                                })
                            }).catch((error)=>{
                                console.log(error)
                                toast.error("Error getting Vehicle Profile data, Please try again later !!")
                            })
                           
                        }).catch((error)=>{
                            console.log(error)
                            toast.error("Error uploading Monthly Running data, Please try again later !!")
                        })
                    }
                    else{
                        toast.error("Monthly running for this month already exists, Please delete and try again !!");
                    }
                }).catch((err)=>{
                    console.log(err)
                toast.error("Error getting Monthly Running data, Please try again later !!")
                })

        
        }
     }


  return (
    <div>
        <ToastContainer/>
     <div className='flex sm:flex-row sm:flex-wrap justify-center max-sm:flex-col'>
               
               <div className='sm:w-2/5 max-sm:w-full sm:m-1 p-3 text-center'>
               <label>Fleet Name</label>
               <Select
 
   
   name="fleetname"
   options={fleetNameDropdown}
   className="basic-multi-select"
   classNamePrefix="select"
   onChange={(e)=>{setSelectedFleetName(e.label);setData((prev)=>({...prev,fleetProfile:e.value}));}}
//    onChange={(e)=>{const selectedFleetNameArr=[];e.map((data)=>{selectedFleetNameArr.push(data.label)});setSelectedFleetName(selectedFleetNameArr)}}
 />
               </div>
               <div className='sm:w-2/5 max-sm:w-full sm:m-1 p-3 text-center'>
               <label>Vehicle Reg. No.</label>
               <Select
 
   
   name="vehicleRegNo"
   options={vehicleRegNoDropdown}
   className="basic-multi-select"
   classNamePrefix="select"
   onChange={(e)=>{setSelectedVehicleRegNo(e.label);setData((prev)=>({...prev,vehicleProfile:e.value}));}}
 />
               </div>
               <div className='sm:w-2/5 max-sm:w-full flex flex-col sm:m-1 p-3 text-center'>
                <label>Select Month</label>
                <input className='p-1 rounded-md border-[1px] border-gray-300' onChange={(e)=>{setData((prev)=>({...prev,month:e.target.value}))}} type='month'></input>
               </div>
               <div className='sm:w-2/5 max-sm:w-full flex flex-col sm:m-1 p-3 text-center'>
               <label>KMs Running</label>
               <input onChange={(e)=>{setData((prev)=>({...prev,kmsRun:parseInt(e.target.value)}))}} className='p-1 rounded-md border-[1px] border-gray-300' type="number" ></input>
               </div>
               <div className='sm:w-4/5 max-sm:w-full flex flex-col sm:m-1 p-3 text-center'>
               <label>Remarks</label>
               <input onChange={(e)=>{setData((prev)=>({...prev,remarks:e.target.value}))}} className='p-1 rounded-md border-[1px] border-gray-300' type="text" ></input>
               </div>
               </div>
               <button onClick={()=>{handleSubmit()}} className='m-auto h-full justify-items-center bg-blue-400 text-white p-2 border-2 border-blue-400 hover:bg-slate-200 hover:text-black px-4 hover: rounded-lg'>Submit</button>

    </div>
  )
}

export default MonthlyRunningForm
